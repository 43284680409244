import React from 'react'
import { useLocation } from 'react-router-dom';
import { AiOutlineSearch } from "react-icons/ai";
import { FaBars } from "react-icons/fa";
import useDateChart  from "../../Zustand/Store";

export default function Navbar({ setOpen }) {
    const location = useLocation();
    const setDateFrom = useDateChart((state) => state.setFromDate);
    const setDateTo = useDateChart((state) => state.setToDate);

    return (
        <div className="flex w-full lg:w-10/12 gap-2 pt-2 items-center justify-between p-1 fixed z-40 backdrop-filter backdrop-blur-md backdrop-brightness-100 pb-3">
            <div className="w-1/12 lg:hidden">
                <button
                    onClick={() => {
                        setOpen()
                    }}
                >
                    <FaBars size="25px" />
                </button>
            </div>
            <div className="w-3/12">
                <p className=" font-font font-bold text-sm lg:text-xl">
                    {location.pathname === "/"
                        ? "Dashboard"
                        : location.pathname.split("/")[1]}
                </p>
            </div>
            <div
                className="w-4/12 rounded-lg border border-gray-200 relative"
                style={{ boxShadow: "0px 0px 7px rgb(222, 220, 220" }}
            >
                <input
                    type="search"
                    className="w-full p-2 pl-8 rounded-lg outline-none"
                    placeholder="cherche ... "
                />
                <AiOutlineSearch
                    size="25px"
                    className=" absolute left-1 top-2 text-black"
                />
            </div>

            <div className="w-3/12 flex justify-center items-center gap-1 lg:mr-20 mr-6">
                <div
                    className="flex w-1/2 rounded-md border border-gray-200"
                    style={{ boxShadow: "0px 0px 7px rgb(222, 220, 220" }}
                >
                    <input
                        type="date"
                        className="w-full rounded-md p-1"
                        onChange={(e) => setDateFrom(e.target.value)}
                        defaultValue={`${new Date().getFullYear()}-01-01`}
                    />
                </div>
                <div
                    className="flex w-1/2 rounded-md border border-gray-200"
                    style={{ boxShadow: "0px 0px 7px rgb(222, 220, 220" }}
                >
                    <input
                        type="date"
                        className="w-full rounded-md p-1"
                        onChange={(e) => setDateTo(e.target.value)}
                        defaultValue={`${new Date().getFullYear()}-12-31`}
                    />
                </div>
            </div>
        </div>
    )
}
