import React from "react";
import Edit from "../../assets/Images/edit.png";
import { BsTrash3 } from "react-icons/bs";
import { GetPolitique, RemovePolitique } from "../../Firebase/Application";
import { useNavigate } from "react-router-dom";


export default function Politiques() {
  const [data, setData] = React.useState([]);
  const [reload, setReload] = React.useState(true);
  const navigate = useNavigate();
  async function FetchData() {
    const dd = await GetPolitique();
    setData([...dd]);
    console.log(dd)
  }
  React.useEffect(() => {
    FetchData()
  }, [reload])

  async function RemovePolit(id) {
    const b = await RemovePolitique(id);
    if (b) {
      setReload(!reload)
    }
  }

  return (
    <div>
      <div
        className="overflow-auto border-gray-200 w-full rounded-lg mt-10 "
        style={{ boxShadow: "0px 0px 25px #d6d2d2", borderRadius: "10px" }}
      >
        <table
          className="table-auto border w-full text-center "
          style={{ borderRadius: "10px", minWidth: "800px" }}
        >
          <thead style={{ borderRadius: "10px" }} className="text-pramery font-semibold ">
            <tr className=" bg-gray-100 ">
              <th className="pb-3 pt-3 font-medium ">Titre </th>
              <th className="pb-3 pt-3 font-medium"> Dérnier éditeur</th>
              <th className="pb-3 pt-3 font-medium">Action </th>
            </tr>
          </thead>
          <tbody className=" rounded-lg " style={{ borderRadius: "10px" }}>
            {
              data.length > 0 && data.map((item, index) => {
                return <tr className="  rounded-lg mt-3 gap-2 " key={index}>
                  <td className="pt-4 pb-4 text-base mt-10">
                    {item.titre}
                  </td>
                  <td className="pt-4 pb-4 text-base mt-10">{item.dernier_editeur}</td>
                  <td className="pt-4 pb-4 text-sm text-gray-400  ">
                    <div className="flex gap-3 justify-center">
                      <button onClick={() => { navigate(item.id) }}>
                        <img src={Edit} className=" w-5 h-5" alt="edit" />
                      </button>
                      <button onClick={() => { RemovePolit(item.id) }}>
                        <BsTrash3 size="20px" className=" text-gray-600" />
                      </button>
                    </div>
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}
