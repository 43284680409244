import React from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import {
  FetchFeedback,
  fetchFeedBackWithPagination,
  NbFeedBack,
} from '../../Firebase/Application'
export default function Feedback() {
  const [data, setData] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState(1)
  const [nbPage, setNbPage] = React.useState(0)
  const ITEMS_PER_PAGE = 6
  const [lastDoc, setLastDoc] = React.useState()
  const [FirstDoc, setFirstDoc] = React.useState()
  const [rate] = React.useState({
    0: 'Le pire ',
    1: 'Pas bon',
    2: 'Bien',
    3: 'Très Bien ',
    4: 'Excellent',
  })

  async function Fetching() {
    const dd = await FetchFeedback()
    setData([...dd])
  }
  async function nb() {
    const nb = await NbFeedBack()
    setNbPage(nb)
  }

  React.useEffect(() => {
    Fetching()
    nb()
  }, [])

  function formatDuration(durationInSeconds) {
    const date = new Date(durationInSeconds.seconds * 1000) // convert seconds to milliseconds and create a Date object
    const formattedDate = date.toISOString().slice(0, 10) // format the date to a string in "YYYY-MM-DD" format
    return formattedDate
  }
  const paginationNext = async () => {
    const da = await fetchFeedBackWithPagination(
      lastDoc.date_inscription, // Use lastDoc as the reference point
      ITEMS_PER_PAGE,
      'next'
    )
    setData(da)
    setLastDoc(da[da.length - 1])
    setFirstDoc(da[0])
  }

  const paginationPrevious = async () => {
    const da = await fetchFeedBackWithPagination(
      FirstDoc.date_inscription, // Use firstDoc as the reference point
      ITEMS_PER_PAGE,
      'previous'
    )
    setData(da)
    setLastDoc(da[da.length - 1])
    setFirstDoc(da[0])
  }

  return (
    <div className="w-full max-h-max">
      <div
        className="overflow-auto border-gray-200 w-full rounded-lg "
        style={{ boxShadow: '0px 0px 25px #d6d2d2', borderRadius: '16px' }}
      >
        <table
          className="table-auto border  w-full text-center pb-10"
          style={{ borderRadius: '16px', minWidth: '1000px' }}
        >
          <thead className="border-b-[1px] border-b-gray-200 text-gray-400  ">
            <tr className="bg-white">
              <th className="pb-3 pt-3 font-medium">ID Feedback </th>
              <th className="pb-3 pt-3 font-medium">Feedback </th>
              <th className="pb-3 pt-3 font-medium">Rate</th>
              <th className="pb-3 pt-3 font-medium">Utilisateur</th>
              <th className="pb-3 pt-3 font-medium">Adresse email </th>
              <th className="pb-3 pt-3 font-medium">Date d’envoi </th>
            </tr>
          </thead>
          <tbody className=" rounded-lg">
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className=""
                    style={{
                      background:
                        index % 2 === 0
                          ? 'rgb(245, 245, 245,0.7)'
                          : 'rgb(255, 255, 255)',
                    }}
                  >
                    <td className="pt-5 pb-5 text-base">
                      {item.id.slice(0, 8)}
                    </td>
                    <td
                      className="pt-5 pb-5 text-base"
                      style={{ maxWidth: '300px' }}
                    >
                      {item.description}
                    </td>
                    <td className="pt-5 pb-5 text-base">{rate[item.rating]}</td>
                    <td className="pt-5 pb-5 text-base ">
                      <div className="flex gap-3 justify-center items-center">
                        <img
                          src={item.img}
                          className="w-8 h-8 rounded-lg"
                          alt="img_user"
                        />
                        <p>{item.fullnam}</p>
                      </div>
                    </td>
                    <td className="pt-5 pb-5 text-sm text-gray-400">
                      {item.email}
                    </td>
                    <td className="pt-5 pb-5 text-sm text-gray-400">
                      {formatDuration(item.created_at)}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
      <div className=" w-full flex lg:justify-end  mt-5">
        <div className="lg:w-1/4 w-1/2 m-auto lg:m-0 flex h-full justify-between p-1 text-sm text-gray-400">
          <div>Nomber de page {Math.ceil(nbPage / ITEMS_PER_PAGE)}</div>
          <div>1-{currentPage} sur 6</div>
          <div className="flex gap-2 ">
            <button
              disabled={currentPage === 1}
              onClick={() => {
                setCurrentPage(currentPage - 1)
                paginationPrevious()
              }}
              className=" relative z-50  w-5 h-5"
            >
              <AiOutlineLeft size="20px" />
            </button>
            <button
              disabled={currentPage > Math.ceil(nbPage / ITEMS_PER_PAGE) - 1}
              onClick={() => {
                setCurrentPage(currentPage + 1)
                paginationNext()
              }}
              className=" relative z-50 w-5 h-5"
            >
              <AiOutlineRight size="20px" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
