import React from "react";
import logo from "../assets/Images/logo.svg";
import Per from "../assets/Images/personenew.png";
import Envoy from "../components/Forget /Envoy";
import Valide from "../components/Forget /Valide";
import Pass from "../components/Forget /Pass";

export default function Login() {
  const [rend, setRend] = React.useState(0);
  const [code, setCode] = React.useState("");
  return (
    <div className=" w-screen h-screen flex max-h-max font-font">
      <div className="w-1/2 hidden lg:flex flex-col justify-center items-center relative ">
        <img src={Per} alt="personnes" className="w-full h-full" />
      </div>

      <div className="w-full m-auto lg:m-0 lg:w-1/2 max-h-max gap-10">
        <div className="m-auto w-1/2 flex flex-col relative justify-center items-center mt-[10%]">
          <img src={logo} alt="logo" className="h-4/5 w-1/4 " />
          <p className=" text-pramery text-[30px] font-bold">Panel Admin </p>
        </div>
        {rend === 0 ? (
          <Envoy
            rend={(v) => {
              setRend(v);
            }}
          />
        ) : rend === 1 ? (
          <Valide
            code={(v) => {
              setCode(v);
            }}
            rend={(v) => {
              setRend(v);
            }}
          />
        ) : (
          <Pass code={code} />
        )}
      </div>
    </div>
  );
}
