import React from "react";
import notification from "../assets/Images/notification_page.svg";
import logo from "../assets/Images/logo.svg";
import { FetchNotification, changeVue, MakeAllMarque } from '../Firebase/Notification'


export default function Notification() {
  const [reload, setReload] = React.useState(false);
  const [data, setData] = React.useState([]);
  async function changev(id) {
    const b = await changeVue(id);
    if (b)
      setReload(!reload)
  }
  async function MakeAll() {
    const b = await MakeAllMarque();
    if (b) setReload(!reload)
  }

  async function FetchingNotifi() {
    const dd = await FetchNotification();
    setData([...dd]);
  }

  React.useEffect(() => {
    FetchingNotifi();
  }, [reload])

  function formatDuration(durationInSeconds) {
    const date = new Date(durationInSeconds.seconds * 1000); // convert seconds to milliseconds and create a Date object
    const formattedDate = date.toISOString().slice(0, 10); // format the date to a string in "YYYY-MM-DD" format
    return formattedDate;
  }


  return (
    <>
      <button onClick={() => {
        MakeAll();
      }} className="text-lg text-gray-400 mt-5 ">Marquer tout comme lu</button>
      <div className="flex lg:flex-row flex-col w-full justify-center items-center mt-5 gap-3 lg:gap-0">
        <div className="w-11/12 lg:w-3/4 flex flex-col gap-5 order-2 lg:order-1 ">
          {
            data.length > 0 && data.map((e, index) => {
              return (
                <div
                  className={e.vue ? " bg-white w-11/12 rounded-lg flex border-gray-200 cursor-pointer " : " bg-gray-200 cursor-pointer w-11/12 rounded-lg flex border-gray-200 "}
                  style={{ boxShadow: "0px 0px 10px #c2bebe" }}
                  key={index}
                  onClick={() => {
                    if (!e.vue)
                      changev(e.id);
                  }}
                >
                  <div className="flex flex-col w-3/4 p-5">
                    <p className="text-md lg:font-semibold font-medium ">
                      {e.description}
                    </p>
                    <p className="mt-4 text-gray-400 text-sm">
                      {formatDuration(e.date_creation)}
                    </p>
                  </div>
                  <div className="w-1/4 flex justify-center items-center">
                    <img src={logo} alt="logo" />
                  </div>
                </div>
              );
            })
          }

        </div>
        <div className="lg:w-1/4 w-9/12 order-1 lg:order-2 flex justify-center items-center pb-4">
          <img src={notification} alt="notifiaction" />
        </div>
      </div>
    </>
  );
}
