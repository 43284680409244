import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { EnregestirContentById, uploadImageToFirebase, FetchingPolitiqueById } from '../../Firebase/Application'
import { useAuth } from "../../Hooks/Context";
import { useLocation, useNavigate } from "react-router-dom";


export default function PolitiqueById() {
    const navigate = useNavigate();
    const [titre, setTitre] = React.useState("");
    const editorRef = useRef(null);
    const [stop, setStop] = useState();
    const [id, setId] = useState("")
    const { user } = useAuth();
    // const [data, setData] = React.useState(null);
    const location = useLocation();
    const [content, setContent] = React.useState('')

    async function Enregistre() {
        setStop(true)
        if (!user || !user.fullnam) {
            console.error("Invalid user data");
            return;
        }
        if (editorRef.current) {
            const content = `${editorRef.current.getContent()}`
            const d = await EnregestirContentById(id, content, user.fullnam, titre);
            if (d) navigate("/Application/Politiques")
        }
        setStop(false)
    }

    async function FetchingById() {
        const dd = await FetchingPolitiqueById(location.pathname.split("/Application/Politiques/")[1]);
        console.log(dd)
        setTitre(dd.titre)
        setContent(dd.description);
        console.log(dd.id)
        setId(dd.id)
    }

    React.useEffect(() => {
        FetchingById()
    }, []);


    function handleImageUpload(cb, file) {
        uploadImageToFirebase(file)
            .then((downloadURL) => {
                if (downloadURL) {
                    cb(downloadURL, { title: file.name });
                }
            })
            .catch((error) => {
                console.log("Image upload error:", error);
            });
    }


    return (
        <div className="w-full h-max">
            <div className=" w-full h-20 p-1 flex justify-center">
                <input
                    onChange={(e) => {
                        setTitre(e.target.value);
                    }}
                    // defaultValue={data && data.titre}
                    value={titre}
                    placeholder="titre"
                    className="w-44 h-10 rounded-md m-auto p-3 border border-gray-400"
                />

            </div>
            <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={content}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        "a11ychecker",
                        "advlist",
                        "advcode",
                        "advtable",
                        "autolink",
                        "checklist",
                        "export",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "powerpaste",
                        "fullscreen",
                        "formatpainter",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                    ],
                    toolbar:
                        "undo redo | casechange blocks | bold italic backcolor | image|link|" +
                        "alignleft aligncenter alignright alignjustify | " +
                        "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
                    content_style:
                        "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
                    file_picker_types: "image", // Allow file picker for images only
                    file_picker_callback: function (cb, value, meta) {
                        const input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");

                        input.onchange = function () {
                            const file = this.files[0];
                            handleImageUpload(cb, file)
                        };
                        input.click();
                    },
                }}
            />
            <div className="w-full flex justify-end items-center mt-10">
                <button
                    onClick={() => {
                        Enregistre();
                    }}
                    className="p-2 bg-pramery rounded-lg text-white"
                    disabled={stop}
                >
                    Enregistrer
                </button>
            </div>
        </div>
    );
}
