import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const Chart = ({ dataChart }) => {
  const options = {
    tension: 0.4,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    elements: {
      line: {
        fill: true,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    backgroundColor: "white",
  };

  const labels = [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Jui",
    "Juil",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Déc",
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        fill: true,
        label: "Revenue",
        data: dataChart,
        borderColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, 0, 400, 0);
          gradient.addColorStop(0.4, "rgb(68, 147, 182,0.5)"); // blue
          gradient.addColorStop(0.8, "rgb(227, 150, 109,0.5)"); // orange
          return gradient;
        },
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, 0, 400, 0);
          gradient.addColorStop(0.4, "rgb(68, 147, 182,0.5)"); // blue
          gradient.addColorStop(0.8, "rgb(227, 150, 109,0.5)"); // orange
          return gradient;
        },
      },
    ],
  };

  return <Line data={data} options={options} />;
};

export default Chart;
