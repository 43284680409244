import React, { useState } from 'react'
import { IoIosAdd } from 'react-icons/io'
import { FetchAbonnmentById, updatePlan } from '../Firebase/Typesubscribe'
import { useLocation } from 'react-router-dom'
import { BsFillTrashFill } from 'react-icons/bs'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function ModificationAbonnement() {
  const location = useLocation()
  const [price, setPrice] = React.useState(0)
  const [plan, setPlan] = React.useState(null)
  const [name, setName] = React.useState('')
  const [text, setText] = React.useState('')
  const [stop, setStop] = React.useState(false)
  const [duration, setDuration] = React.useState(0)
  const [reload, setReload] = React.useState('')
  const [id, setId] = React.useState('')
  const [nb, setNb] = React.useState(0)

  async function Fetching() {
    const dd = await FetchAbonnmentById(
      location.pathname.split('/Abonnement/')[1]
    )
    setPrice(dd['price'])
    setPlan(dd['plan'])
    setName(dd['name'])
    setId(dd['id'])
    setNb(dd['nbAnnounces'])
  }

  React.useEffect(() => {
    Fetching()
  }, [reload])

  async function Enregistrer() {
    setStop(true)
    if (duration !== 0 && price !== 0 && nb !== 0) {
      try {
        await updatePlan(plan, price, id, nb, duration)
        // Code to execute after the successful completion of the updatePlan function
      } catch (error) {
        // Handle the error here
        console.error('Error occurred:', error)
      }
      toast.success('Abonnement modifier avec success')
      setStop(false)
      return
    }
    setStop(false)
    toast.error("Veuillez remplir les champs d'abord")
  }

  return (
    <div className="w-full max-h-max ">
      <ToastContainer />
      <div className="mt-3 text-pramery text-lg">
        Abonnement &gt; {name} &gt; Modifier
      </div>
      <div className="w-full lg:w-[80%] flex flex-col justify-start gap-4 mt-6">
        <div>
          <p className="text-gray-400 text-lg ">Prix de l'obonnement</p>
          <div className=" w-full rounded-lg border border-pramery flex p-2">
            <input
              type="number"
              className="w-full outline-none"
              value={price}
              onChange={(e) => {
                setPrice(e.target.value)
              }}
            />
            <p className=" text-xl text-pramery">DA</p>
          </div>
        </div>
        <div>
          <p className="text-gray-400 text-lg ">Prix par date</p>
          <div className=" w-full rounded-lg border border-pramery flex p-2 gap-5">
            <p
              className={
                duration === 7
                  ? ' p-3 bg-green-300 text-white rounded-lg cursor-pointer '
                  : ' p-3 bg-gray-500 text-white hover:bg-green-300 rounded-lg cursor-pointer '
              }
              onClick={() => {
                setDuration(7)
              }}
            >
              par Smaine{' '}
            </p>
            <p
              className={
                duration === 30
                  ? ' p-3 bg-green-300 text-white rounded-lg cursor-pointer '
                  : ' p-3 bg-gray-500 text-white hover:bg-green-300 rounded-lg cursor-pointer '
              }
              onClick={() => {
                setDuration(30)
              }}
            >
              {' '}
              par Mois{' '}
            </p>
            <p
              className={
                duration === 365
                  ? ' p-3 bg-green-300 text-white rounded-lg cursor-pointer '
                  : ' p-3 bg-gray-500 text-white hover:bg-green-300 rounded-lg cursor-pointer '
              }
              onClick={() => {
                setDuration(365)
              }}
            >
              par Annees
            </p>
          </div>
        </div>
        <div>
          <p className="text-gray-400 text-lg ">
            nombre announces qui peut poster
          </p>
          <div className=" w-full rounded-lg border border-pramery flex p-2">
            <input
              type="number"
              className="w-full outline-none"
              value={nb}
              onChange={(e) => {
                setNb(e.target.value)
              }}
            />
          </div>
        </div>
        <div>
          <p className="text-gray-400 text-lg ">Ajout option</p>
          <div className=" w-full rounded-lg border border-pramery flex p-2">
            <input
              type="text"
              className="w-full outline-none"
              value={text}
              onChange={(e) => {
                setText(e.target.value)
              }}
            />
            <button className=" text-xl text-pramery">
              <IoIosAdd
                size="30px"
                className=" font-bold"
                onClick={() => {
                  if (text !== '') {
                    setPlan([{ status: false, description: text }, ...plan])
                    setText('')
                  }
                }}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-7 border border-gray-300 rounded-lg w-full lg:w-[80%]">
        <p className=" text-gray-300 p-2 pl-10">
          Choisissez les options du plan
        </p>
        <div className=" flex flex-col p-2">
          {plan &&
            plan.map((e, index) => {
              return (
                <div
                  className="flex gap-3 p-4 hover:bg-green-100 rounded-md justify-between"
                  key={index}
                >
                  <div className="flex gap-2">
                    <div className="w-[10px]"></div>
                    <input
                      type="checkbox"
                      defaultChecked={e.status}
                      onClick={(event) => {
                        plan[index].status = event.target.checked
                      }}
                    />
                    <p>{e.description}</p>
                  </div>
                  <button
                    onClick={() => {
                      const newArray = plan.filter(
                        (e, indexs) => indexs !== index
                      )
                      setPlan([...newArray])
                    }}
                  >
                    <BsFillTrashFill size="20px" />
                  </button>
                </div>
              )
            })}
        </div>
      </div>
      <div className="w-full flex justify-end p-3 mt-3">
        <button
          className=" p-2 text-white rounded-md bg-pramery"
          onClick={async () => {
            await Enregistrer()
          }}
          disabled={stop}
        >
          Enregistrer
        </button>
      </div>
    </div>
  )
}
