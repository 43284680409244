import React from 'react'
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { MdBlock, MdDone } from 'react-icons/md'
import { BsThreeDots } from 'react-icons/bs'
import { AiOutlineEye } from 'react-icons/ai'
import userImage from '../assets/Images/user.png'

import {
  NomberAnnounceIdUser,
  fetchAnnounceIdUser,
  paginationAnnounces,
} from '../Firebase/AnnouncesOperation'
import { changeStatusAnnounce } from '../Firebase/AnnouncesOperation'
export default function UtilisateurId() {
  const location = useLocation()
  const [data, setData] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState(1)
  const [nbPage, setNbPage] = React.useState(0)
  const ITEMS_PER_PAGE = 6
  const [lastDoc, setLastDoc] = React.useState(null)
  const [FirstDoc, setFirstDoc] = React.useState(null)
  const navigate = useNavigate()
  const [reload, setReload] = React.useState(false)

  const taggler = async (event, id) => {
    if (
      event.currentTarget.childNodes[1] &&
      event.currentTarget.childNodes[1].textContent == 'block'
    ) {
      // console.log(event.currentTarget)
      event.currentTarget.childNodes[1].textContent = 'active'
      // event.currentTarget.childNodes[0].style.display = "none";
      event.currentTarget.parentElement.style.display = 'none'
      event.currentTarget.parentElement.parentElement.parentElement.childNodes[7].childNodes[0].textContent =
        'block'
      event.currentTarget.parentElement.parentElement.parentElement.childNodes[7].childNodes[0].style.backgroundColor =
        '#fca292'
      event.currentTarget.parentElement.parentElement.parentElement.childNodes[7].childNodes[0].style.color =
        'red'
      await changeStatusAnnounce(id, 'block')
      setReload(!reload)
    } else {
      event.currentTarget.childNodes[1].textContent = 'block'
      // event.currentTarget.childNodes[0].style.display = "block";
      event.currentTarget.parentElement.style.display = 'none'
      event.currentTarget.parentElement.parentElement.parentElement.childNodes[7].childNodes[0].textContent =
        'active'
      event.currentTarget.parentElement.parentElement.parentElement.childNodes[7].childNodes[0].style.backgroundColor =
        '#9effc0'
      event.currentTarget.parentElement.parentElement.parentElement.childNodes[7].childNodes[0].style.color =
        'green'
      await changeStatusAnnounce(id, 'active')
      setReload(!reload)
    }
  }

  function formatDuration(durationInSeconds) {
    const date = new Date(durationInSeconds * 1000) // convert seconds to milliseconds and create a Date object
    const formattedDate = date.toISOString().slice(0, 10) // format the date to a string in "YYYY-MM-DD" format
    return formattedDate
  }

  const paginationNext = async () => {
    const da = await paginationAnnounces(
      location.pathname.split('/Utilisateur/')[1],
      lastDoc.start_date,
      ITEMS_PER_PAGE,
      'next'
    )
    setData(da)
    setLastDoc(da[da.length - 1])
    setFirstDoc(da[0])
  }

  const paginationPrevious = async () => {
    const da = await paginationAnnounces(
      location.pathname.split('/Utilisateur/')[1],
      FirstDoc.start_date,
      ITEMS_PER_PAGE,
      'previous'
    )
    setData(da)
    setLastDoc(da[da.length - 1])
    setFirstDoc(da[0])
  }

  const dating = async () => {
    const da = await fetchAnnounceIdUser(
      location.pathname.split('/Utilisateur/')[1]
    )
    const n = await NomberAnnounceIdUser(
      location.pathname.split('/Utilisateur/')[1]
    )
    setNbPage(n)
    setData(da)
    setFirstDoc(da[0])
    setLastDoc(da[da.length - 1])
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    dating()
  }, [reload])

  return (
    <>
      <div className="flex w-full justify-start pt-5 text-base text-gray-600 gap-2">
        <p className="">
          Utilisateur <span className="ml-2"> &gt;</span>{' '}
        </p>
        <p>
          {location.pathname.split('/Utilisateur/')[1].slice(0, 8)}
          <span className=" pl-3">&gt;</span>
          <span className=" pl-2"> {data[0] && data[0].user.fullname}</span>
        </p>
      </div>
      <div
        className="overflow-auto border-gray-200 w-full rounded-lg mt-10"
        style={{ boxShadow: '0px 0px 25px #d6d2d2', borderRadius: '16px' }}
      >
        <table
          className="table-auto border  w-full text-center pb-10"
          style={{ borderRadius: '16px', minWidth: '1000px' }}
        >
          <thead className="border-b-[1px] border-b-gray-200 text-gray-400  ">
            <tr className="bg-white">
              <th className="pb-5 pt-5 font-medium">ID Annonce </th>
              <th className="pb-5 pt-5 font-medium">Destination </th>
              <th className="pb-5 pt-5 font-medium">Date de publication </th>
              <th className="pb-5 pt-5 font-medium">Annonceur</th>
              <th className="pb-5 pt-5 font-medium">Wilaya </th>
              <th className="pb-5 pt-5 font-medium">Type de voyage </th>
              <th className="pb-5 pt-5 font-medium">Transport </th>
              <th className="pb-5 pt-5 pr-3 font-medium">Status </th>
              <th className="pb-5 pt-5 pr-3 font-medium">Action </th>
            </tr>
          </thead>
          <tbody className=" rounded-lg">
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="relative"
                    style={{
                      background:
                        index % 2 === 0 ? 'rgb(245, 245, 245,0.7)' : 'white',
                    }}
                  >
                    <td className="pt-5 pb-5 text-base">
                      {item.announce_id.slice(0, 8)}
                    </td>
                    <td className="pt-5 pb-5 text-base">{item.destination}</td>
                    <td className="pt-5 pb-5 text-base">
                      {formatDuration(item.start_date.seconds)}
                    </td>
                    <td className="pt-5 pb-5 text-base flex gap-3 ">
                      <div className=" flex justify-center items-center gap-2">
                        <img
                          src={item.user.img || userImage}
                          className="w-8 h-8 rounded-lg"
                          alt="img_user"
                        />
                        <p>{item.user.fullname}</p>
                      </div>
                    </td>
                    <td className="pt-5 pb-5 text-sm text-gray-400">
                      {item.starting_location}
                    </td>
                    <td className="pt-5 pb-5 text-sm text-gray-400">
                      {item.trip_type}
                    </td>
                    <td className="pt-5 pb-5 text-sm text-gray-400">
                      {item.vehicle_type}
                    </td>
                    <td className="pt-5 pb-5 text-gray-400">
                      <span
                        className={
                          item.status === 'active'
                            ? 'bg-green-200 p-2 rounded-xl text-green-600 text-xs cursor-pointer'
                            : 'bg-red-200 p-2 rounded-xl text-red-500 text-xs cursor-pointer'
                        }
                      >
                        {item.status}
                      </span>
                    </td>
                    <td className="pt-6 pb-6">
                      <button
                        className=" cursor-pointer"
                        onClick={(event) => {
                          if (event.currentTarget.parentElement.childNodes[1]) {
                            if (
                              event.currentTarget.parentElement.childNodes[1]
                                .style.display == 'none'
                            ) {
                              event.currentTarget.parentElement.childNodes[1].style.display =
                                'flex'
                            } else {
                              event.currentTarget.parentElement.childNodes[1].style.display =
                                'none'
                            }
                          }
                        }}
                      >
                        <BsThreeDots size="20px" />
                      </button>
                      <div
                        className=" w-40 flex-col border border-gray-400  bg-white rounded-lg absolute top-0 right-14 justify-center items-center gap-1 p-1 z-20"
                        style={{ display: 'none' }}
                      >
                        <button
                          className=" flex justify-evenly text-xs w-full hover:bg-gray-200 rounded-xl p-2"
                          onClick={() => {
                            navigate(`/Anonces/${item.announce_id}`)
                          }}
                        >
                          <AiOutlineEye size="20px" />
                          <span>Affiche announce </span>
                        </button>
                        <button
                          className=" flex text-xs justify-evenly w-full hover:bg-gray-200 rounded-xl p-1"
                          onClick={async (event) => {
                            await taggler(event, item.id)
                          }}
                        >
                          {item.status === 'active' ? (
                            <MdBlock size="20px" />
                          ) : (
                            <MdDone size="20px" />
                          )}
                          <span>
                            {item.status === 'block' ? 'active' : 'block'}
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
      <div className=" w-full flex lg:justify-end  mt-5">
        <div className="lg:w-1/4 w-1/2 m-auto lg:m-0 flex h-full justify-between p-1 text-sm text-gray-400">
          <div>Nomber de page {Math.ceil(nbPage / ITEMS_PER_PAGE)} </div>
          <div>1-{currentPage} sur 6</div>
          <div className="flex gap-2 ">
            <button
              disabled={currentPage === 1}
              onClick={() => {
                setCurrentPage(currentPage - 1)
                paginationPrevious()
              }}
              className=" relative z-50  w-5 h-5"
            >
              <AiOutlineLeft size="20px" />
            </button>
            <button
              disabled={currentPage > Math.ceil(nbPage / ITEMS_PER_PAGE) - 1}
              onClick={() => {
                setCurrentPage(currentPage + 1)
                paginationNext()
              }}
              className=" relative z-50 w-5 h-5"
            >
              <AiOutlineRight size="20px" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

// TODO test pagination
