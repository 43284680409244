import React from 'react'
import logo from '../assets/Images/logo.svg'
import Per from '../assets/Images/personenew.png'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { getData_doc, makeAdmin, signin } from '../Firebase/Operation'
import { useAuth } from '../Hooks/Context'

export default function Login() {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error1, setError1] = React.useState('')
  const [error2, setError2] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [type, setType] = React.useState('password')
  const [stop, setStop] = React.useState(false)
  // const pas = React.useRef();
  const navigate = useNavigate()
  const { update } = useAuth()
  const checkbox = React.useRef()

  const handleSubmit = async (e) => {
    setStop(true)
    const Exp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (!Exp.test(email)) {
      setError1('Address email invalide')
    } else {
      setError1('')
    }
    if (password.length < 6) {
      setError2('Mot de passe incorrect')
    } else {
      setError2('')
    }
    if (error1 !== '' || error2 !== '') {
      setStop(false)
      return
    }
    // e.preventDefault();

    const user = await signin(email, password, checkbox.current.checked)
    if (user.user) {
      const querySnapshot = await getData_doc('/users')
      var doc_user = null
      querySnapshot.forEach((doc) => {
        if (
          doc.id === user.user.uid &&
          (doc.data().role === 'admin' || doc.data().role === 'subadmin')
        ) {
          // if (doc.data().role === "admin") {
          //   makeAdmin(user.user.uid);
          // }
          doc_user = doc.data()
        }
      })
      if (doc_user === null) {
        setError1('Address email invalide')
      } else {
        update(doc_user)
        navigate('/')
      }
      setStop(false)
    } else {
      setError1('Address email invalide')
      setStop(false)
    }
  }
  return (
    <div className=" w-screen h-screen  flex max-h-max font-font ">
      <div className="w-1/2 hidden lg:flex flex-col justify-center items-center relative max-h-max ">
        <img src={Per} alt="personnes" className=" bg-cover w-full h-full" />
      </div>

      <div className="w-full m-auto lg:m-0 lg:w-1/2 max-h-max gap-10 ">
        <div className="m-auto w-1/2 flex flex-col relative justify-center items-center mt-[7%]">
          <img src={logo} alt="logo" className="h-3/5 w-1/4 " />
          <p className=" text-pramery text-[30px] font-bold">Panel Admin </p>
        </div>
        <div className="w-3/4 max-w-sm lg:w-1/2 min-h-[50%] m-auto max-h-max flex flex-col items-center gap-8 mt-10">
          <div className="w-full gap-2 flex flex-col relative ">
            <p className="text-[#4493B6]">Email</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={
                error1 === ''
                  ? 'w-full h-14 rounded-md border border-gray-400 p-4'
                  : 'w-full h-14 rounded-md border border-red-300 p-4'
              }
            />
            <p className="text-red-400 text-sm m-auto ">{error1}</p>
          </div>
          <div className="w-full gap-2 flex flex-col">
            <p className="text-[#4493B6]"> password</p>
            <div
              className={
                error2 === ''
                  ? 'w-full h-14 rounded-md border border-gray-400 flex relative'
                  : 'w-full h-14 rounded-md border border-red-400 flex relative'
              }
            >
              <input
                // ref={pas}
                type={type}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full h-full pl-4 pr-10 rounded-md border"
              />
              {open ? (
                <button
                  className="absolute right-0 top-3 mr-1"
                  onClick={(e) => {
                    setOpen(!open)
                    setType('password')
                  }}
                >
                  <AiOutlineEye size="30px" />
                </button>
              ) : (
                <button
                  className="absolute right-0 top-3 mr-1"
                  onClick={(e) => {
                    setOpen(!open)
                    setType('text')
                  }}
                >
                  <AiOutlineEyeInvisible size="30px" />
                </button>
              )}
            </div>
            <p className="text-red-400 text-sm m-auto">{error2}</p>
          </div>
          <div className="w-full flex justify-between lg:text-sm text-[12px] ">
            <div className="flex gap-1 ">
              <input type="checkbox" ref={checkbox} />
              <p>Se souvenir de moi</p>
            </div>
            <div>
              <Link to="/forget" className="text-[#4493B6]">
                Mot de pass oublie ?
              </Link>
            </div>
          </div>
          <button
            onClick={(e) => {
              handleSubmit()
            }}
            className="w-full rounded-md bg-pramery h-14 text-white text-lg font-semibold"
            disabled={stop}
          >
            Se connecter
          </button>
        </div>
      </div>
    </div>
  )
}
