import React from 'react'
import announce from '../assets/Images/admin_annonce.svg'
import users from '../assets/Images/admin_usersss.svg'
import heart from '../assets/Images/Heart.svg'
import user from '../assets/Images/admin_user.svg'
import location from '../assets/Images/admin_map.svg'
import Chart from '../components/Chart/Chart'
import { fetchAnnounce } from '../Firebase/AnnouncesOperation'
import { NumberUsers } from '../Firebase/UserOperation'
import { NomberOfFavorite } from '../Firebase/favorite'
import { getWilays, MostVisiting } from '../Firebase/Wilays'
import useDateChart from '../Zustand/Store'
import { fetchDateOfChart } from '../Firebase/ChartOperation'
import userImage from '../assets/Images/user.png'

export default function Dashboard() {
  const [data, setData] = React.useState([])
  const [nbUsers, setNbUsers] = React.useState(0)
  const [nbFavorite, setNbFavorite] = React.useState(0)
  const [nbWilays, setNbWilays] = React.useState(0)
  const [mostW, setMostW] = React.useState([])
  const [dateChart, setDateChart] = React.useState([])
  const dateFrom = useDateChart((state) => state.FromDate)
  const dateTo = useDateChart((state) => state.ToDate)

  async function getDatechart() {
    const chartdate = await fetchDateOfChart(dateFrom, dateTo)
    setDateChart([...chartdate])
  }
  async function changeMostW() {
    const most = await MostVisiting(dateFrom, dateTo)
    setMostW([...most])
  }
  async function changeWilayNb() {
    const nb = await getWilays(dateFrom, dateTo)
    setNbWilays(nb)
  }
  const dating = async () => {
    const dt = await fetchAnnounce()
    setData(dt)
    const n = await NumberUsers(dateFrom, dateTo)
    setNbUsers(n)
  }
  function formatDuration(durationInSeconds) {
    const date = new Date(durationInSeconds * 1000) // convert seconds to milliseconds and create a Date object
    const formattedDate = date.toISOString().slice(0, 10) // format the date to a string in "YYYY-MM-DD" format
    return formattedDate
  }
  async function changeNombFavorite() {
    const nb = await NomberOfFavorite(dateFrom, dateTo)
    setNbFavorite(nb)
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    dating()
    changeNombFavorite()
    changeWilayNb()
    changeMostW()
    getDatechart()
  }, [dateFrom, dateTo])

  return (
    <>
      <div className="font-font">
        <p className="text-md font-font font-light">Statistiques </p>
        <div className="p-1 mt-3 flex flex-wrap gap-[5%] ">
          {/* card1 */}
          <div
            style={{ boxShadow: '0px 0px 7px rgb(222, 220, 220)' }}
            className=" w-[19%] h-28 border border-gray-200 flex items-center justify-center rounded-xl min-w-[45%] lg:min-w-[10%] m-1"
          >
            <div className="w-10 flex justify-center h-10 items-center relative rounded-[50%] bg-[#d2f2ff]">
              <img src={announce} alt="announce" />
            </div>
            <div className="w-1/2 flex flex-col justify-center items-center">
              <p className=" text-2xl font-bold text-gray-600">{data.length}</p>
              <p className=" font-normal text-gray-300 text-sm">annonces</p>
            </div>
          </div>
          {/* card1 */}
          <div
            style={{ boxShadow: '0px 0px 7px rgb(222, 220, 220)' }}
            className=" w-[19%] h-28 border border-gray-200 flex items-center justify-center rounded-xl min-w-[45%] lg:min-w-[10%] m-1"
          >
            <div className="w-10 flex justify-center h-10 items-center relative rounded-[50%] bg-[#ffe2d2]">
              <img src={user} alt="announce" />
            </div>
            <div className="w-1/2 flex flex-col justify-center items-center">
              <p className=" text-2xl font-bold text-gray-600">{nbUsers}</p>
              <p className=" font-normal text-gray-300 text-sm">Utilisateurs</p>
            </div>
          </div>
          <div
            style={{ boxShadow: '0px 0px 7px rgb(222, 220, 220)' }}
            className=" w-[19%] h-28 border border-gray-200 flex items-center justify-center rounded-xl min-w-[45%] lg:min-w-[10%] m-1"
          >
            <div className="w-10 flex justify-center h-10 items-center relative rounded-[50%] bg-[#d6f4ff] ">
              <img src={heart} alt="announce" />
            </div>
            <div className="w-1/2 flex flex-col justify-center items-center">
              <p className=" text-2xl font-bold text-gray-600">{nbFavorite}</p>
              <p className=" font-normal text-gray-300 text-sm">Favoris</p>
            </div>
          </div>
          {/* card1 */}
          <div
            style={{ boxShadow: '0px 0px 7px rgb(222, 220, 220)' }}
            className=" w-[19%] h-28 border border-gray-200 flex items-center justify-center rounded-xl min-w-[45%] lg:min-w-[10%] m-1"
          >
            <div className="w-10 flex justify-center h-10 items-center relative rounded-[50%] bg-[#ffe2d2]">
              <img src={location} alt="announce" />
            </div>
            <div className="w-1/2 flex flex-col justify-center items-center">
              <p className=" text-2xl font-bold text-gray-600">{nbWilays}</p>
              <p className=" font-normal text-gray-300 text-sm">Wilayas</p>
            </div>
          </div>
        </div>
        <p className="mt-5 text-md font-font font-light pb-5">Revenu</p>
        <div className="w-[99%] flex max-h-max flex-col lg:flex-row justify-between pb-10 gap-5 lg:gap-0">
          {/* graphe  */}
          <div
            className="w-full lg:w-[66%] border border-gray-200 rounded-lg order-2 lg:order-1"
            style={{ boxShadow: '0px 0px 7px rgb(222, 220, 220)' }}
          >
            <Chart dataChart={dateChart} />
          </div>
          {/* table de remaque  */}
          <div
            className="w-10/12 m-auto lg:w-[32%] border border-gray-200 rounded-lg gap-6 flex flex-col pb-3 order-1 lg:order-2"
            style={{ boxShadow: '0px 0px 7px rgb(222, 220, 220)' }}
          >
            <p className="text-sm font-medium p-4 text-gray-600">
              {' '}
              Les wilayas les plus visitées
            </p>
            {mostW.length > 0 &&
              mostW.map((e, index) => {
                return (
                  <div
                    className="w-2/3 m-auto flex justify-between items-center border-b-[1px] border-b-gray-200 p-1"
                    key={index}
                  >
                    <div className="flex flex-col gap-1 justify-center items-center w-[48%]">
                      <div className="w-12 h-12 bg-[#ffe2d2] rounded-[50%] flex items-center justify-center">
                        <img src={location} alt="walay" />
                      </div>
                      <p>{e.wilay.toLowerCase()}</p>
                    </div>
                    <div className="flex gap-3 items-center w-[48%]">
                      <p className="text-lg font-semibold">{e.nb} </p>
                      <p className="text-sm">Voyages</p>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        <p className="text-md font-font font-light pb-5">Nouvelles annonces </p>
        <div
          className="overflow-auto border-gray-200 w-full rounded-lg "
          style={{ boxShadow: '0px 0px 25px #d6d2d2', borderRadius: '16px' }}
        >
          <table
            className="table-auto border  w-full text-center pb-10"
            style={{ borderRadius: '16px', minWidth: '1000px' }}
          >
            <thead className="border-b-[1px] border-b-gray-200 text-gray-400  ">
              <tr className="bg-white">
                <th className="pb-5 pt-5 font-medium">ID Annonce </th>
                <th className="pb-5 pt-5 font-medium">Destination </th>
                <th className="pb-5 pt-5 font-medium">Date de publication </th>
                <th className="pb-5 pt-5 font-medium">Annonceur</th>
                <th className="pb-5 pt-5 font-medium">Wilaya </th>
                <th className="pb-5 pt-5 font-medium">Type de voyage </th>
                <th className="pb-5 pt-5 font-medium">Transport </th>
                <th className="pb-5 pt-5 pr-3 font-medium">Status </th>
              </tr>
            </thead>
            <tbody className=" rounded-lg">
              {data.length > 0 &&
                data.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className=""
                      style={{
                        background:
                          index % 2 === 0 ? 'rgb(245, 245, 245,0.7)' : 'white',
                      }}
                    >
                      <td className="pt-5 pb-5 text-base">
                        {item.announce_id.slice(0, 8)}
                      </td>
                      <td className="pt-5 pb-5 text-base">
                        {item.destination}
                      </td>
                      <td className="pt-5 pb-5 text-base">
                        {formatDuration(item.start_date.seconds)}
                      </td>
                      <td className="pt-5 pb-5 text-base flex gap-3 ">
                        <div className=" w-full max-h-max flex gap-2 justify-center items-center">
                          <img
                            src={item.user.img || userImage}
                            className="w-8 h-8 rounded-lg"
                            alt=""
                          />
                          <p>{item.user.fullname}</p>
                        </div>
                      </td>
                      <td className="pt-5 pb-5 text-sm text-gray-400">
                        {item.starting_location}
                      </td>
                      <td className="pt-5 pb-5 text-sm text-gray-400">
                        {item.trip_type}
                      </td>
                      <td className="pt-5 pb-5 text-sm text-gray-400">
                        {item.vehicle_type}
                      </td>
                      <td className="pt-5 pb-5 text-gray-400">
                        <span
                          className={
                            item.status === 'active'
                              ? 'bg-green-200 p-2 rounded-xl text-green-600 text-xs'
                              : 'bg-red-200 p-2 rounded-xl text-red-500 text-xs'
                          }
                        >
                          {item.status}
                        </span>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
