import React from "react";
import SideBar from "../components/SideBar/SideBar";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";

export default function Home() {

  const [open, setOpen] = React.useState(false);

  return (
    <div className="flex max-h-max">
      <SideBar
        open={open}
        setOpen={() => {
          setOpen(!open);
        }}
      />
      <div className="w-full lg:w-4/5 p-3 pt-0 lg:ml-[20%] ">
        <Navbar setOpen={() => { setOpen(!open) }} />
        <div className="pt-14 font-font relative">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
