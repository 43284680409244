import React from "react";
import logo from "../../assets/Images/logo_white.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Admin from "../../assets/Images/admin.svg";
import { AiOutlineClose } from "react-icons/ai";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { useAuth } from "../../Hooks/Context";
import { signout } from "../../Firebase/Operation";
import {
  AnnounceIcon,
  ApplicationIcon,
  DashboardIcon,
  NotificationIcon,
  SettingIcon,
  TicketIcon,
  UsersICon,
  DemandsIcon
} from "../../assets/ComponentIcon";

export default function SideBar({ open, setOpen }) {
  const { user, update } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {


  }, [location.pathname]);

  return (
    <div
      className={
        open
          ? "w-3/5 h-screen fixed z-50 overflow-auto flex flex-col bg-pramery transition transform translate-x-0  "
          : "md:w-1/5 h-screen fixed z-50 overflow-auto lg:flex flex-col bg-pramery hidden transition"
      }
    >
      <div className="flex flex-col w-2/3 m-auto mt-4 gap-10">
        <div className="w-full h-4 flex justify-end lg:hidden ">
          <button
            onClick={() => {
              setOpen();
            }}
          >
            <AiOutlineClose size="25px" className="text-white" />
          </button>
        </div>
        <div className="w-full m-auto h-20 flex justify-center items-center lg:mt-5 ">
          <img src={logo} alt="logo" />
        </div>
        <div className="w-full m-auto flex flex-col items-center gap-4 ">


          {user.role === "admin" ?
            <div className="group group1">
              <Link to="/" className="flex gap-3 text-left w-40 ">
                <p className="mt-1">
                  <DashboardIcon
                    color={location.pathname === "/" ? "white" : "gray"}
                    className="dashoard"
                  />
                </p>{" "}
                <p className={location.pathname === "/" ? " font-font group-hover:text-white text-white text-lg " : " font-font group-hover:text-white text-gray-500 text-lg "}>
                  Dashboard
                </p>
              </Link>
            </div>
            : user.role === "subadmin" && user.permission[0].Dashboard ?
              <div className="group group1">
                <Link to="/" className="flex gap-3 text-left w-40 ">
                  <p className="mt-1">
                    <DashboardIcon
                      color={location.pathname === "/" ? "white" : "gray"}
                      className="dashoard"
                    />
                  </p>{" "}
                  <p className={location.pathname === "/" ? " font-font group-hover:text-white text-white text-lg " : " font-font group-hover:text-white text-gray-500 text-lg "}>
                    Dashboard
                  </p>
                </Link>
              </div>
              : null
          }



          {user.role === "admin" ?
            <div className="group2 group">
              <Link to="Utilisateur" className="flex gap-3 w-40 text-left ">
                <p className="mt-1">
                  <UsersICon
                    color={
                      location.pathname.split("/")[1] === "Utilisateur"
                        ? "white"
                        : "gray"
                    }
                  />
                </p>
                <p className={location.pathname.split("/")[1] === "Utilisateur" ? "font-font group-hover:text-white text-white text-lg" : "font-font group-hover:text-white text-gray-500 text-lg "}>
                  Utilisateur
                </p>
              </Link>
            </div>
            : user.role === "subadmin" && user.permission[0].Utilisateur ?
              <div className="group2 group">
                <Link to="Utilisateur" className="flex gap-3 w-40 text-left ">
                  <p className="mt-1">
                    <UsersICon
                      color={
                        location.pathname.split("/")[1] === "Utilisateur"
                          ? "white"
                          : "gray"
                      }
                    />
                  </p>
                  <p className={location.pathname.split("/")[1] === "Utilisateur" ? "font-font group-hover:text-white text-white text-lg" : "font-font group-hover:text-white text-gray-500 text-lg "}>
                    Utilisateur
                  </p>
                </Link>
              </div> : null
          }


          {
            user.role === "admin" ?
              <div className="group3 group">
                <Link to="Anonces" className="flex gap-3 text-left w-40 ">
                  <p className="mt-1">
                    <AnnounceIcon
                      color={
                        location.pathname.split("/")[1] === "Anonces"
                          ? "white"
                          : "gray"
                      }
                    />
                  </p>{" "}
                  <p className={location.pathname.split("/")[1] === "Anonces" ? " font-font group-hover:text-white text-white text-lg " : " font-font group-hover:text-white text-gray-500 text-lg "}>
                    Annonces
                  </p>
                </Link>
              </div>
              : user.role === "subadmin" && user.permission[0].Announces ?
                <div className="group3 group">
                  <Link to="Anonces" className="flex gap-3 text-left w-40 ">
                    <p className="mt-1">
                      <AnnounceIcon
                        color={
                          location.pathname.split("/")[1] === "Anonces"
                            ? "white"
                            : "gray"
                        }
                      />
                    </p>{" "}
                    <p className={location.pathname.split("/")[1] === "Anonces" ? " font-font group-hover:text-white text-white text-lg " : " font-font group-hover:text-white text-gray-500 text-lg "}>
                      Annonces
                    </p>
                  </Link>
                </div>
                : null
          }


          {
            user.role === "admin" ?
              <div className="group4 group">
                <Link to="Abonnement" className="flex gap-3 w-40 text-left ">
                  <p className="mt-1">
                    <TicketIcon
                      color={
                        location.pathname.split("/")[1] === "Abonnement"
                          ? "white"
                          : "gray"
                      }
                    />
                  </p>
                  <p className={location.pathname.split("/")[1] === "Abonnement" ? " font-font group-hover:text-white text-white text-lg" : " font-font group-hover:text-white text-gray-500 text-lg "}>
                    Abonnement
                  </p>
                </Link>
              </div>
              : user.role === "subadmin" && user.permission[0].Abonnement ?
                <div className="group4 group">
                  <Link to="Abonnement" className="flex gap-3 w-40 text-left ">
                    <p className="mt-1">
                      <TicketIcon
                        color={
                          location.pathname.split("/")[1] === "Abonnement"
                            ? "white"
                            : "gray"
                        }
                      />
                    </p>
                    <p className={location.pathname.split("/")[1] === "Abonnement" ? " font-font group-hover:text-white text-white text-lg" : " font-font group-hover:text-white text-gray-500 text-lg "}>
                      Abonnement
                    </p>
                  </Link>
                </div>
                : null
          }


          {
            user.role === "admin" ?
              <div className="group4 group">
                <Link to="Demands" className="flex gap-3 w-40 text-left ">
                  <p className="mt-1">
                    <DemandsIcon
                      color={
                        location.pathname.split("/")[1] === "Demands"
                          ? "white"
                          : "gray"
                      }
                    />
                  </p>
                  <p className={location.pathname.split("/")[1] === "Demands" ? " font-font group-hover:text-white text-white text-lg" : " font-font group-hover:text-white text-gray-500 text-lg "}>
                    Demands
                  </p>
                </Link>
              </div>
              : user.role === "subadmin" && user.permission[0].Demands ?
                <div className="group4 group">
                  <Link to="Demands" className="flex gap-3 w-40 text-left ">
                    <p className="mt-1">
                      <DemandsIcon
                        color={
                          location.pathname.split("/")[1] === "Demands"
                            ? "white"
                            : "gray"
                        }
                      />
                    </p>
                    <p className={location.pathname.split("/")[1] === "Demands" ? " font-font group-hover:text-white text-white text-lg" : " font-font group-hover:text-white text-gray-500 text-lg "}>
                      Demands
                    </p>
                  </Link>
                </div>
                : null
          }



          {
            user.role === "admin" ?
              <div className="group5 group">
                <Link to="Application" className="flex gap-3 w-40 text-left ">
                  <p className="mt-1">
                    <ApplicationIcon
                      color={
                        location.pathname.split("/")[1] === "Application"
                          ? "white"
                          : "gray"
                      }
                    />
                  </p>{" "}
                  <p className={location.pathname.split("/")[1] === "Application" ? "font-font group-hover:text-white text-white text-lg " : "font-font group-hover:text-white text-gray-500 text-lg "}>
                    Application
                  </p>
                </Link>
              </div>
              : user.role === "subadmin" && user.permission[0].Application ?
                <div className="group5 group">
                  <Link to="Application" className="flex gap-3 w-40 text-left ">
                    <p className="mt-1">
                      <ApplicationIcon
                        color={
                          location.pathname.split("/")[1] === "Application"
                            ? "white"
                            : "gray"
                        }
                      />
                    </p>{" "}
                    <p className={location.pathname.split("/")[1] === "Application" ? "font-font group-hover:text-white text-white text-lg " : "font-font group-hover:text-white text-gray-500 text-lg "}>
                      Application
                    </p>
                  </Link>
                </div>
                : null
          }


          <div className="group6 group">
            <Link to="Notification" className="flex gap-3 w-40 text-left ">
              <p className="mt-1">
                <NotificationIcon
                  color={
                    location.pathname.split("/")[1] === "Notification"
                      ? "white"
                      : "gray"
                  }
                />
              </p>{" "}
              <p className={location.pathname.split("/")[1] === "Notification" ? "font-font group-hover:text-white text-white text-lg " : "font-font group-hover:text-white text-gray-500 text-lg "}>
                Notification
              </p>
            </Link>
          </div>
          <div className="group7 group">
            <Link to="Parametre" className="flex gap-3 w-40 text-left">
              <p className="mt-1">
                <SettingIcon
                  color={
                    location.pathname.split("/")[1] === "Parametre"
                      ? "white"
                      : "gray"
                  }
                />
              </p>{" "}
              <p className={location.pathname.split("/")[1] === "Parametre" ? "font-font group-hover:text-white text-white text-lg" : "font-font group-hover:text-white text-gray-500 text-lg"}>
                Parametre
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-5/6 m-auto mb-10 gap-[5%] items-center ">
        <div className="bg-white rounded-3xl flex items-center justify-center p-2 w-10 h-10">
          <img src={Admin} alt="admin" />
        </div>
        <div className="flex flex-col ">
          <p className="text-white text-md font-normal">{user.nom + " " + user.prenom}</p>
          <p className="text-gray-400">{user.role}</p>
        </div>
        <button
          onClick={() => {
            signout().then(() => {
              update({});
              sessionStorage.removeItem(
                "firebase:authUser:AIzaSyDuX08nZV7p6cIZAGJMc8l_ujU1zBUsArw:[DEFAULT]"
              );
              navigate("/login");
            });
          }}
        >
          <RiLogoutCircleRLine size="30px" />
        </button>
      </div>
    </div >
  );
}
