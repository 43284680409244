import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { MdNoteAdd } from "react-icons/md";
export default function Application() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="w-full max-h-max mt-10">
      <div className="w-full flex justify-between ">
        <div className="flex w-[60%] lg:w-[50%] justify-between p-1">
          <button
            onClick={() => {
              navigate("/Application");
            }}
            className={
              location.pathname.split("/")[2] == undefined
                ? " bg-pramery text-white p-1 rounded-lg"
                : " text-gray-400 hover:bg-pramery hover:rounded-lg hover:text-white p-1"
            }
          >
            Slider
          </button>
          <button
            onClick={() => {
              navigate("/Application/Wilayas");
            }}
            className={
              location.pathname.split("/")[2] == "Wilayas"
                ? " bg-pramery text-white p-1 rounded-lg"
                : " text-gray-400 hover:bg-pramery hover:rounded-lg hover:text-white p-1"
            }
          >
            Wiliyas
          </button>
          <button
            onClick={() => {
              navigate("/Application/Feedback");
            }}
            className={
              location.pathname.split("/")[2] == "Feedback"
                ? " bg-pramery text-white p-1 rounded-lg"
                : " text-gray-400 hover:bg-pramery hover:rounded-lg hover:text-white p-1"
            }
          >
            {" "}
            Feedback
          </button>
          <button
            onClick={() => {
              navigate("/Application/Politiques");
            }}
            className={
              location.pathname.split("/")[2] == "Politiques"
                ? " bg-pramery text-white p-1 rounded-lg"
                : " text-gray-400 hover:bg-pramery hover:rounded-lg hover:text-white p-1"
            }
          >
            {" "}
            Politique
          </button>
        </div>
        {location.pathname.split("/")[2] == "Politiques" && (
          <button
            className="p-1"
            onClick={() => {
              navigate("/Application/ajoutOUmodification");
            }}
          >
            <MdNoteAdd size={30} />
          </button>
        )}
      </div>
      <div className="mt-5">
        <Outlet />
      </div>
    </div>
  );
}
