import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  orderBy,
  limit,
  updateDoc,
  startAfter,
  where,
  endBefore,
} from 'firebase/firestore'

import { db } from './InitialseApp'

export async function fetchDemands() {
  const q = query(
    collection(db, 'paiements'),
    orderBy('payedAt', 'desc'),
    limit(6)
  )
  const data = await getDocs(q)
  const tripAnnounces = []
  for (const doce of data.docs) {
    const userRef = doc(db, 'users', doce.data().userId)
    const user = await getDoc(userRef)
    const abanmenet = doc(
      db,
      'subscriptionPlans',
      doce.data().subscriptionPlanId
    )
    const abanmenetData = await getDoc(abanmenet)
    tripAnnounces.push({
      paiement_id: doce.id,
      ...doce.data(),
      user: user.data(),
      NameAbonnement: abanmenetData.data().name,
    })
  }

  return tripAnnounces
}

export async function NomberDemands() {
  const q = query(collection(db, 'paiements'), orderBy('payedAt', 'desc'))
  const data = await getDocs(q)
  const tripAnnounces = []
  for (const doce of data.docs) {
    tripAnnounces.push({ announce_id: doce.id, ...doce.data() })
  }
  return tripAnnounces.length
}

export async function changeStatusDemand(
  id,
  status,
  userId,
  subscriptionPlanId
) {
  const userRef = doc(db, 'users', userId)
  console.log(userRef)
  await updateDoc(userRef, {
    subscription: {
      payedAt: new Date(),
      subscriptionPlanId: subscriptionPlanId,
    },
  })
  const paimentRef = doc(db, 'paiements', id)
  await updateDoc(paimentRef, {
    status: status,
  })
  return true
}

export async function paginationDemandsWithoutId(
  lastOrFirst,
  ITEMS_PER_PAGE,
  type
) {
  const collectionRef = collection(db, 'paiements')
  let queryRef

  if (type === 'next') {
    queryRef = query(
      collectionRef,
      orderBy('payedAt', 'desc'),
      limit(ITEMS_PER_PAGE),
      startAfter(lastOrFirst)
    )
  } else {
    queryRef = query(
      collectionRef,
      orderBy('payedAt', 'desc'),
      limit(ITEMS_PER_PAGE),
      endBefore(lastOrFirst)
    )
  }

  const querySnapshot = await getDocs(queryRef)
  const tripAnnounces = []

  for (const docing of querySnapshot.docs) {
    const userRef = doc(db, 'users', docing.data().userId)
    const userSnapshot = await getDoc(userRef)
    const user = userSnapshot.data()
    const abanmenet = doc(
      db,
      'subscriptionPlans',
      docing.data().subscriptionPlanId
    )
    const abanmenetData = await getDoc(abanmenet)
    tripAnnounces.push({
      paiement_id: docing.id,
      ...docing.data(),
      user: user,
      NameAbonnement: abanmenetData.data().name,
    })
  }

  return tripAnnounces
}
