import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  orderBy,
  limit,
  updateDoc,
  startAfter,
  where,
  endBefore,
} from 'firebase/firestore'

import { db } from './InitialseApp'

export async function NomberOfFavorite(dateFrom, dateTo) {
  const newDateFrom = new Date(dateFrom)
  const newDateTo = new Date(dateTo)
  const q = query(
    collection(db, 'favorite'),
    where('created_at', '>=', newDateFrom),
    where('created_at', '<=', newDateTo)
  )
  const data = await getDocs(q)
  return data.size
}
