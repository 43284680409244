import {
    collection,
    getDocs,
    getDoc,
    doc,
    query,
    orderBy,
    limit,
    updateDoc,
    startAfter,
    where,
    endBefore,
} from "firebase/firestore";
import { db } from "./InitialseApp";


export async function FetchNotification() {
    const collectionRef = collection(db, "notification");
    const queryRef = query(
        collectionRef,
        orderBy("date_creation", "desc")
    );
    const querySnapshot = await getDocs(queryRef);
    const notifications = querySnapshot.docs.map((doc) => { return { ...doc.data(), id: doc.id } });
    return notifications;
}




export async function changeVue(id) {
    const userRef = doc(db, "notification", id);
    await updateDoc(userRef, {
        vue: true,
    });
    return true;
}

export async function MakeAllMarque() {
    const collectionRef = collection(db, "notification");
    const queryRef = query(
        collectionRef,
        orderBy("date_creation", "desc")
    );
    const querySnapshot = await getDocs(queryRef);
    const notifications = querySnapshot.docs.map((doc) => { return doc.id });
    for (const no of notifications) {
        changeVue(no)
    }
    return true; 
}

