import React from "react";
import Profile from "../components/Prametre/Profile";
import Configuration from "../components/Prametre/Configuration";
import Moditor from "../components/Prametre/Moditor";
import { MdOutlineAdminPanelSettings } from "react-icons/md";



export default function Parametre() {
  const [choose, setChoose] = React.useState("pr");
  const [add, setAdd] = React.useState(false);

  return (
    <>
      <div className="mt-10 w-full font-font flex gap-5 relative justify-between">
        <div className="flex gap-10">
          <button
            className={
              choose === "pr" ? "p-2 text-white bg-pramery rounded-lg" : ""
            }
            onClick={() => {
              setChoose("pr");
            }}
          >
            Mon profil
          </button>
          <button
            className={
              choose === "mo" ? "p-2 text-white bg-pramery rounded-lg" : ""
            }
            onClick={() => {
              setChoose("mo");
            }}
          >
            Modérateurs
          </button>
          {/* <button
            className={
              choose === "co" ? "p-2 text-white bg-pramery rounded-lg" : ""
            }
            onClick={() => {
              setChoose("co");
            }}
          >
            Configuration
          </button> */}
        </div>

        <button
          className={
            choose === "mo"
              ? "p-2 text-white bg-pramery gap-1 items-center rounded-lg flex relative float-right "
              : "hidden"
          }
          onClick={() => {
            setAdd(true);
            // document.body.style.background = "gray";
          }}
        >
          <MdOutlineAdminPanelSettings size="30px" />
          <p className="hidden md:flex text-sm">Ajouter modérateur</p>
        </button>
      </div>
      {choose === "pr" ? (
        <Profile />
      ) : (
        <Moditor
          add={add}
          setAdd={() => {
            setAdd(false);
          }}
        />
      )}
    </>
  );
}
