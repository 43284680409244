import {
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from 'firebase/auth'
import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  orderBy,
  limit,
  updateDoc,
  startAfter,
  where,
  endBefore,
} from 'firebase/firestore'

import { db, auth } from './InitialseApp'

// Set a new key for the session
// const customSessionKey = "my-auth-state";
// setCustomUserClaims

export const signout = async () => {
  return await signOut(auth)
    .then(() => {
      // .
      return ' Sign-out successful '
    })
    .catch((error) => {
      // .
      return ' An error happened'
    })
}

export const signin = async (email, password, checkbox) => {
  if (checkbox) {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        return { user: user, error: null }
        // ...
      })
      .catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message
        return { user: null, error: errorMessage }
      })
  } else {
    return await setPersistence(auth, browserSessionPersistence)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user
            return { user: user, error: null }
            // ...
          })
          .catch((error) => {
            // const errorCode = error.code;
            const errorMessage = error.message
            return { user: null, error: errorMessage }
          })
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        const errorMessage = error.message
        return { user: null, error: errorMessage }
      })
  }
}

export const getData_doc = async (col) => {
  const querySnapshot = await getDocs(collection(db, col))
  return querySnapshot
  //   querySnapshot.forEach((doc) => {
  //     console.log(`${doc.id} => ${doc.data()}`);
  //   });
}

export async function SendCodeEmail(email) {
  const verificationCode = Math.floor(Math.random() * 900000) + 100000
  // const emailTemplate = {
  //   subject: "Password Reset Request for ",
  //   body: "Hello ,<br>{{EMAIL}}<br>Your verification code is {{CODE}}.<br>Please use this code to reset your password.<br><br>Thank you,<br>The Fi-tariq Team",
  // };
  const actionCodeSettings = {
    url: 'https://fitariq.page.link',
    handleCodeInApp: true,
    dynamicLinkDomain: 'fitariq.page.link',
    CODE: verificationCode,
    EMAIL: email,
  }

  return await sendPasswordResetEmail(auth, email, actionCodeSettings)
    .then(() => {
      return true
    })
    .catch((error) => {
      console.log(error)
      return false
    })
}

export async function verifieCode(code) {
  return await verifyPasswordResetCode(auth, code)
    .then(() => {
      return true
    })
    .catch((error) => {
      // An error ocurred
      // ...
      return false
    })
}

export async function confirmPassword(code, password) {
  return await confirmPasswordReset(code, password)
    .then(() => {
      return true
    })
    .catch((error) => {
      // An error ocurred
      // ...
      return false
    })
}

// const handleResetPassword = (e) => {
//   e.preventDefault();
//   confirmPasswordReset(auth, code, newPassword)
//     .then(() => {
//       console.log("Password reset successfully!");
//       setSuccess(true);
//     })
//     .catch((error) => {
//       console.error("Error resetting password: ", error);
//     });
// };

// export async function makeAdmin(uid) {
//   return await auth
//     .setCustomUserClaims(uid, { admin: true })
//     .then(() => {
//       return true;
//     })
//     .catch(() => {
//       return false;
//     });
// }

//    fetch of data

// s
