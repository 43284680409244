import {
  collection,
  getDocs,
  doc,
  query,
  updateDoc,
  setDoc,
} from 'firebase/firestore'
import {
  updateEmail,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth'
import { db, auth } from './InitialseApp'
import { api } from '../apis/apis'

export async function getUserAdminData(email) {
  const collectionRef = collection(db, 'users')
  const queryRef = query(collectionRef)
  const data = await getDocs(queryRef)
  const users = []
  for (const doc of data.docs) {
    if (doc.data().role !== 'client' && doc.data().email === email) {
      users.push({ id_user: doc.id, ...doc.data() })
    }
  }
  return users[0]
}

export async function EregistreData(
  userEmail,
  nom,
  tel,
  email,
  prenom,
  curpass,
  nvpas
) {
  if (curpass !== '' && nvpas !== '') {
    try {
      // Get the current user
      const user = auth.currentUser
      // Prompt the user to re-authenticate if necessary (e.g., to ensure they entered the correct current password)
      const credentials = EmailAuthProvider.credential(userEmail, curpass)
      await reauthenticateWithCredential(user, credentials)
      // Update the email
      await updateEmail(user, email)
      // Update the password
      await updatePassword(user, nvpas)
      // Password changed successfully
      // console.log(userEmail)
      const collectionRef = collection(db, 'users')
      const queryRef = query(collectionRef)
      const data = await getDocs(queryRef)
      const users = []
      for (const doc of data.docs) {
        if (doc.data().role !== 'client' && doc.data().email === userEmail) {
          users.push({ id_user: doc.id, ...doc.data() })
        }
      }
      const userRef = doc(db, 'users', users[0].id_user)
      await updateDoc(userRef, {
        email: email,
        tel: tel,
        nom: nom,
        prenom: prenom,
      })
      return true
    } catch (error) {
      // Handle any errors that occur
      return false
    }
  } else {
    try {
      const collectionRef = collection(db, 'users')
      const queryRef = query(collectionRef)
      const data = await getDocs(queryRef)
      const users = []
      for (const doc of data.docs) {
        if (doc.data().role !== 'client' && doc.data().email === userEmail) {
          users.push({ id_user: doc.id, ...doc.data() })
        }
      }

      const userRef = doc(db, 'users', users[0].id_user)
      await updateDoc(userRef, {
        email: email,
        tel: tel,
        nom: nom,
        prenom: prenom,
      })

      return true
    } catch (error) {
      // Handle any errors that occur
      return false
    }
  }
}

export async function GetSubAdmin() {
  const collectionRef = collection(db, 'users')
  const queryRef = query(collectionRef)
  const data = await getDocs(queryRef)
  const users = []
  for (const doc of data.docs) {
    if (doc.data().role === 'subadmin' && !doc.data().isremove) {
      users.push({ id_user: doc.id, ...doc.data() })
    }
  }
  return users
}

export async function remove(id) {
  const userRef = doc(db, 'users', id)
  try {
    await updateDoc(userRef, {
      isremove: true,
    })
    return true
  } catch {
    return false
  }
}

export async function modificationUser(token, lastEmail, dd) {
  if (dd['password'] !== '' || dd['email'] !== lastEmail) {
    const pass = dd['password']
    const email = dd['email']
    let headersList = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    }

    let bodyContent = `newPassword=${pass}&email=${lastEmail}&newEmail=${email}`

    try {
      let response = await fetch(`${api}/api/change_password`, {
        method: 'POST',
        body: bodyContent,
        headers: headersList,
      })
      if (response.status !== 200) {
        return false
      }
      const userRef = doc(db, 'users', dd['id_user'])
      await updateDoc(userRef, {
        nom: dd['nom'],
        prenom: dd['prenom'],
        tel: dd['tel'],
        permission: dd['permission'],
        email: dd['email'],
      })

      return true
    } catch (err) {
      console.log(err)
      return false
    }
  } else {
    const userRef = doc(db, 'users', dd['id_user'])
    try {
      await updateDoc(userRef, {
        nom: dd['nom'],
        prenom: dd['prenom'],
        tel: dd['tel'],
        permission: dd['permission'],
        email: dd['email'],
      })
      return true
    } catch {
      return false
    }
  }
}

export async function CreateSubAdmin(token, dd) {
  let headersList = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: token,
  }
  let bodyContent = `password=${dd['password']}&email=${dd['email']}`
  try {
    let response = await fetch(`${api}/api/creactAccount`, {
      method: 'POST',
      body: bodyContent,
      headers: headersList,
    })
    if (response.status !== 200) {
      return false
    }
    const data = await response.json()
    const documentRef = doc(db, 'users', data.id)
    await setDoc(documentRef, {
      nom: dd['nom'],
      prenom: dd['prenom'],
      tel: dd['tel'],
      permission: dd['permission'],
      email: dd['email'],
      role: 'subadmin',
    })
    return true
  } catch (err) {
    console.log(err)
    return false
  }
}
