import React from 'react'
import { BiImageAdd } from 'react-icons/bi'
import { FetchWilays } from '../../Firebase/Application'
import { BsTrashFill } from 'react-icons/bs'
import { EnregistreWilays } from '../../Firebase/Application'
import { reload } from 'firebase/auth'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function Wilayas() {
  const [Wilayas, setWilays] = React.useState([])
  const [WilayasNew, setWilaysNew] = React.useState([])
  const [curent, setCurent] = React.useState(null)
  // const [url, setUrl] = React.useState(null);
  // const [newUrl, setNewUrl] = React.useState(null);
  const [stop, setStop] = React.useState(false)
  const [reload, setReload] = React.useState(false)

  async function Fetching() {
    const dd = await FetchWilays()
    setWilays([...dd['wilays']])
  }

  React.useEffect(() => {
    Fetching()
  }, [reload])

  async function UploadImage() {
    setStop(true)
    const b = await EnregistreWilays(Wilayas, WilayasNew)
    setStop(false)
    setWilaysNew([])
    setWilays([])
    setReload(!reload)
    if (b) {
      toast.success('Images enregistrer avec success')
    } else toast.error("Erreur lors de l'enregistrement des images")
  }

  return (
    <>
      <div className="max-h-max w-full lg:w-1/2">
        <ToastContainer />
        <div className=" w-full mt-3">
          <p>Choisissez la wilaya</p>
          <select
            className="w-full mt-3 h-12 rounded-lg bg-white border border-gray-300 p-2"
            onChange={(e) => {
              setCurent(e.target.value)
            }}
          >
            <option value=""></option>
            {Wilayas.length > 0 &&
              Wilayas.map((e, index) => {
                return (
                  <option key={index} value={e.name}>
                    {e.name}
                  </option>
                )
              })}
          </select>
        </div>
        <div className="w-full p-2 mt-5 max-h-max">
          <p>Couverture</p>
          <div className="w-full max-h-max flex flex-wrap justify-start p-3 ">
            <div className="w-44 m-5 h-32 flex items-center justify-center relative border border-gray-400 rounded-lg">
              <input
                id="file"
                className="hidden"
                type="file"
                onChange={(event) => {
                  // setFils([...event.target.files]);
                  const fil = Wilayas.filter((e) => e.name === curent)
                  const filN = WilayasNew.filter((e) => e.name === curent)
                  if (fil[0] && fil[0].url === '' && !filN[0])
                    setWilaysNew([
                      { name: curent, url: event.target.files[0] },
                      ...WilayasNew,
                    ])
                }}
              />
              <label htmlFor="file" className="w-32 ">
                <BiImageAdd className="w-full h-full text-gray-300" />
              </label>
            </div>
            {Wilayas &&
              Wilayas.map((item) => {
                if (item.name === curent && item.url !== '') {
                  return (
                    <div className="w-44 m-5 h-32 flex items-center justify-center relative border border-gray-400 rounded-lg">
                      <button
                        onClick={async () => {
                          Wilayas.forEach((e) => {
                            if (e.name === curent) {
                              e.url = ''
                            }
                          })
                          const filteredArray = WilayasNew.filter(
                            (element) => element.name !== curent
                          )
                          setWilaysNew([...filteredArray])
                        }}
                        className=" absolute top-0 left-0 m-2 "
                      >
                        <BsTrashFill size="18px" className=" text-red-500" />
                      </button>
                      <img
                        src={item.url}
                        alt="images"
                        className="w-full h-full rounded-md bg-cover"
                      />
                    </div>
                  )
                }
              })}
            {WilayasNew &&
              WilayasNew.map((item) => {
                if (item.name === curent) {
                  return (
                    <div className="w-44 m-5 h-32 flex items-center justify-center relative border border-gray-400 rounded-lg">
                      <button
                        onClick={async () => {
                          const filteredArray = WilayasNew.filter(
                            (element) => element.name !== curent
                          )
                          setWilaysNew([...filteredArray])
                        }}
                        className=" absolute top-0 left-0 m-2 "
                      >
                        <BsTrashFill size="18px" className=" text-red-500" />
                      </button>
                      <img
                        src={URL.createObjectURL(item.url)}
                        alt="images"
                        className="w-full h-full rounded-md bg-cover"
                      />
                    </div>
                  )
                }
              })}
          </div>
        </div>
      </div>
      <div className=" mt-6 w-full flex items-center justify-end ">
        <button
          className=" p-2 text-white rounded-md bg-pramery "
          disabled={stop}
          onClick={() => {
            UploadImage()
          }}
        >
          Enregistrer
        </button>
      </div>
    </>
  )
}
