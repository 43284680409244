import React from "react";
import { SendCodeEmail } from "../../Firebase/Operation";
import { useNavigate } from "react-router-dom";

export default function Envoy({ rend }) {
  const [email, setEmail] = React.useState("");

  const [error1, setError1] = React.useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const Exp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // if (!Exp.test(email)) {
    //   setError1("Address email invalide");
    // } else {
    //   setError1("");
    // }
    SendCodeEmail(email).then((res) => {
      if (res) {
        // rend(1);
        alert("check your email to reset your password");
        navigate("/login");
      } else {
        setError1("your email is not found");
      }
    });

    // ResendPasswor(email).then((res) => {
    //   if (res) {
    //     // rend(1); pour le moment n'est pas utile
    //     alert("check your email to reset your password");
    //     navigate("/login");
    //   } else {
    //     setError1("your email is not found");
    //   }
    // });

    if (error1 !== "") return;
    // e.preventDefault();

    // const details = { email, password };
  };

  return (
    <div className="w-3/4 max-w-sm lg:w-1/2 min-h-[50%] m-auto max-h-max flex flex-col items-center gap-8 mt-10">
      <div className="w-full ">
        {" "}
        <p className="text-sm">
          Entrez l’adresse email que vous souhaitez recevoire le code de
          vérification.
        </p>
      </div>
      <div className="w-full gap-2 flex flex-col relative ">
        <p className="text-[#4493B6]">Email</p>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={
            error1 === ""
              ? "w-full h-14 rounded-md border border-gray-400 p-4"
              : "w-full h-14 rounded-md border border-red-300 p-4"
          }
        />
        <p className="text-red-400 text-sm m-auto ">{error1}</p>
      </div>

      <button
        onClick={(e) => {
          handleSubmit();
        }}
        className="w-full rounded-md bg-pramery h-14 text-white text-base font-medium"
      >
        {/* Envoyer le code */}
        Envoyer link
      </button>
    </div>
  );
}
