import React from 'react'
import { AiOutlineRight, AiOutlineLeft, AiFillRead } from 'react-icons/ai'
import paper from '../assets/Images/paper.png'
import { useNavigate } from 'react-router-dom'
import { MdBlock, MdDone } from 'react-icons/md'
import userImage from '../assets/Images/user.png'
import {
  NumberPage,
  fetchUsersWithPagination,
  getUserData,
  changeStatus,
} from '../Firebase/UserOperation'
import { BsThreeDots } from 'react-icons/bs'
export default function Utilisateur() {
  const [currentPage, setCurrentPage] = React.useState(1)
  const [reload, setReload] = React.useState(false)
  const [nbPage, setNbPage] = React.useState(0)
  const [data, setData] = React.useState([])
  const ITEMS_PER_PAGE = 6
  const [lastDoc, setLastDoc] = React.useState()
  const [FirstDoc, setFirstDoc] = React.useState()

  const navigate = useNavigate()

  const taggler = async (event, id) => {
    if (
      event.currentTarget.childNodes[1] &&
      event.currentTarget.childNodes[1].textContent === 'inactive'
    ) {
      // console.log(event.currentTarget)
      event.currentTarget.childNodes[1].textContent = 'active'
      // event.currentTarget.childNodes[0].style.display = "none";
      event.currentTarget.parentElement.style.display = 'none'
      event.currentTarget.parentElement.parentElement.parentElement.childNodes[6].childNodes[0].textContent =
        'inactive'
      event.currentTarget.parentElement.parentElement.parentElement.childNodes[6].childNodes[0].style.backgroundColor =
        '#fca292'
      event.currentTarget.parentElement.parentElement.parentElement.childNodes[6].childNodes[0].style.color =
        'red'
      await changeStatus(id, 'inactive')
      setReload(!reload)
    } else {
      event.currentTarget.childNodes[1].textContent = 'inactive'
      // event.currentTarget.childNodes[0].style.display = "block";
      event.currentTarget.parentElement.style.display = 'none'
      event.currentTarget.parentElement.parentElement.parentElement.childNodes[6].childNodes[0].textContent =
        'active'
      event.currentTarget.parentElement.parentElement.parentElement.childNodes[6].childNodes[0].style.backgroundColor =
        '#9effc0'
      event.currentTarget.parentElement.parentElement.parentElement.childNodes[6].childNodes[0].style.color =
        'green'
      await changeStatus(id, 'active')
      setReload(!reload)
    }
  }

  const dating = async () => {
    const data = await getUserData()
    const n = await NumberPage()
    setNbPage(n)
    setLastDoc(data[data.length - 1])
    setFirstDoc(data[0])
    setData(data)
  }

  const paginationNext = async () => {
    const da = await fetchUsersWithPagination(
      lastDoc.date_inscription, // Use lastDoc as the reference point
      ITEMS_PER_PAGE,
      'next'
    )
    setData(da)
    setLastDoc(da[da.length - 1])
    setFirstDoc(da[0])
  }

  const paginationPrevious = async () => {
    const da = await fetchUsersWithPagination(
      FirstDoc.date_inscription, // Use firstDoc as the reference point
      ITEMS_PER_PAGE,
      'previous'
    )
    setData(da)
    setLastDoc(da[da.length - 1])
    setFirstDoc(da[0])
  }

  function formatDuration(durationInSeconds) {
    const date = new Date(durationInSeconds.seconds * 1000) // convert seconds to milliseconds and create a Date object
    const formattedDate = date.toISOString().slice(0, 10) // format the date to a string in "YYYY-MM-DD" format
    return formattedDate
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    dating()
  }, [reload])

  return (
    <>
      <div
        className="overflow-auto border-gray-200 w-full rounded-lg mt-10"
        style={{ boxShadow: '0px 0px 25px #d6d2d2', borderRadius: '16px' }}
      >
        <table
          className="table-auto border  w-full text-center "
          style={{ borderRadius: '16px', minWidth: '1000px' }}
        >
          <thead className="border-b-[1px] border-b-gray-200 text-gray-400  ">
            <tr className="bg-white">
              <th className="pb-5 pt-5 font-medium">ID Annonce </th>
              <th className="pb-5 pt-5 font-medium">Annonceur</th>
              <th className="pb-5 pt-5 font-medium">Date d’inscription </th>
              <th className="pb-5 pt-5 font-medium">Adresse email </th>
              <th className="pb-5 pt-5 font-medium">Numéro de téléphone </th>
              <th className="pb-5 pt-5 font-medium">Plan d’abonnement </th>

              <th className="pb-5 pt-5 pr-3 font-medium">Status </th>
              <th className="pb-5 pt-5 pr-3 font-medium">Action </th>
            </tr>
          </thead>
          <tbody className=" rounded-lg ">
            {data &&
              data.map((item, index) => {
                return (
                  <tr
                    className=" relative "
                    style={{
                      background:
                        index % 2 === 0 ? 'white' : 'rgb(245, 245, 245,0.7)',
                    }}
                    key={index}
                  >
                    <td className="pt-6 pb-6 text-base">
                      {item.id_user.slice(0, 8)}
                    </td>
                    <td className="pt-6 pb-6 text-base">
                      <div className=" flex justify-center items-center gap-2">
                        <p>{item.fullname}</p>
                        <img
                          src={item.avatar || userImage}
                          alt=""
                          className="w-10 h-10 rounded-lg"
                        />
                      </div>
                    </td>
                    <td className="pt-6 pb-6 text-base">
                      {item.created_at && formatDuration(item.created_at)}
                    </td>
                    <td className="pt-6 pb-6 text-base ">{item.email}</td>
                    <td className="pt-6 pb-6 text-sm text-gray-400">
                      {item.phone}
                    </td>
                    <td className="pt-6 pb-6 text-sm text-gray-400">
                      {item.typePlanAbonnment != null
                        ? item.typePlanAbonnment
                        : "n'est pas plan encore"}
                    </td>
                    <td className="pt-6 pb-6 text-gray-400">
                      <span
                        className={
                          item.status === 'active'
                            ? 'bg-[#9effc0] p-2 rounded-xl text-green-600 text-xs '
                            : 'bg-[#fca292] p-2 rounded-xl text-red-600 text-xs '
                        }
                      >
                        {item.status}
                      </span>
                    </td>
                    <td className="pt-6 pb-6">
                      <button
                        className=" cursor-pointer"
                        onClick={(event) => {
                          if (event.currentTarget.parentElement.childNodes[1]) {
                            if (
                              event.currentTarget.parentElement.childNodes[1]
                                .style.display == 'none'
                            ) {
                              event.currentTarget.parentElement.childNodes[1].style.display =
                                'flex'
                            } else {
                              event.currentTarget.parentElement.childNodes[1].style.display =
                                'none'
                            }
                          }
                        }}
                      >
                        <BsThreeDots size="20px" />
                      </button>
                      <div
                        className=" w-32 flex-col border border-gray-400  bg-white rounded-lg absolute top-0 right-14 justify-center items-center gap-1 p-1 z-20"
                        style={{ display: 'none' }}
                      >
                        <button
                          className=" flex justify-evenly text-xs w-full hover:bg-gray-200 rounded-xl p-2"
                          onClick={() => {
                            navigate(item.id_user)
                          }}
                        >
                          <img src={paper} alt="paper" className="w-3 h-3" />
                          <span>List annonces </span>
                        </button>
                        <button
                          className=" flex text-xs justify-evenly w-full hover:bg-gray-200 rounded-xl p-1"
                          onClick={async (event) => {
                            await taggler(event, item.id_user)
                          }}
                        >
                          {item.status === 'active' ? (
                            <MdBlock size="20px" />
                          ) : (
                            <MdDone size="20px" />
                          )}
                          <span>
                            {item.status === 'inactive' ? 'active' : 'inactive'}
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
      <div className=" w-full flex lg:justify-end  mt-5">
        <div className="lg:w-1/4 w-1/2 m-auto lg:m-0 flex h-full justify-between p-1 text-sm text-gray-400">
          <div>Nomber de page {Math.ceil(nbPage / ITEMS_PER_PAGE)}</div>
          <div>1-{currentPage} sur 6</div>
          <div className="flex gap-2 ">
            <button
              disabled={currentPage === 1}
              onClick={() => {
                setCurrentPage(currentPage - 1)
                paginationPrevious()
              }}
              className=" relative z-50  w-5 h-5"
            >
              <AiOutlineLeft size="20px" />
            </button>
            <button
              disabled={currentPage > Math.ceil(nbPage / ITEMS_PER_PAGE) - 1}
              onClick={() => {
                setCurrentPage(currentPage + 1)
                paginationNext()
              }}
              className=" relative z-50 w-5 h-5"
            >
              <AiOutlineRight size="20px" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
