import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  orderBy,
  limit,
  updateDoc,
  startAfter,
  where,
  endBefore,
} from 'firebase/firestore'

import { db } from './InitialseApp'

export async function FetchAbonnment() {
  const ids = ['plan1', 'plan2', 'plan3']
  let plans = []
  for (const id of ids) {
    const planRef = doc(db, 'subscriptionPlans', id)
    const plan = await getDoc(planRef)
    plans.push({ id: id, ...plan.data() })
  }
  return plans
}

export async function FetchAbonnmentById(id) {
  const planRef = doc(db, 'subscriptionPlans', id)
  const plan = await getDoc(planRef)
  //({ id: id, ...plan.data() })
  return { id: id, ...plan.data() }
}
export async function updatePlan(plan, price, id, nb, duration) {
  const planRef = doc(db, 'subscriptionPlans', id)
  await updateDoc(planRef, {
    price: price,
    plan: plan,
    nbAnnounces: nb,
    duration: duration,
  })
}
