import React from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { EregistreData, getUserAdminData } from '../../Firebase/Parametre';
import { useAuth } from "../../Hooks/Context";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Profile() {
  const { user } = useAuth();
  const [open, setOpen] = React.useState(false);
  const pas = React.useRef();
  const pas2 = React.useRef();
  const [open2, setOpen2] = React.useState(false);

  const [tel, settel] = React.useState("");
  const [email, setemail] = React.useState("");
  const [nom, setnom] = React.useState("");
  const [prenom, setprenom] = React.useState("");
  const [passw, setPassw] = React.useState("");
  const [nvPassw, setNvPassw] = React.useState("");
  const [stop, setStop] = React.useState(false);

  async function FetchingData() {
    const dd = await getUserAdminData(user.email);
    settel(dd.tel);
    setemail(dd.email);
    setnom(dd.nom);
    setprenom(dd.prenom);
  };
  React.useEffect(() => {
    FetchingData();
    toast.done("votre information est enregistrer")
  }, [])


  async function Save() {
    setStop(true)
    const b = await EregistreData(user.email, nom, tel, email, prenom, passw, nvPassw)
    if (b) {
      toast.success("votre information est enregistrer");
      setNvPassw("");
      setPassw("")
    } else {
      toast.error("verife votre information")
    }
    setStop(false)
  }

  return (
    <div>
      <ToastContainer />
      <p className="text-lg text-gray-500 mt-10">Informations personnelles</p>
      <div className="w-full flex flex-wrap p-2">
        <div className="w-full relative lg:w-2/5 max-h-max p-4">
          <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
            <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
              Nom
            </label>
            <input
              type="text"
              className=" p-2 outline-none"
              placeholder="name ...."
              value={nom}
              onChange={(e) => { setnom(e.target.value) }}
            />
          </div>
        </div>
        <div className="w-full relative lg:w-2/5 max-h-max p-4">
          <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
            <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
              Prénom
            </label>
            <input
              type="text"
              className=" p-2 outline-none"
              placeholder="name ...."
              value={prenom}
              onChange={(e) => { setprenom(e.target.value) }}
            />
          </div>
        </div>
        <div className="w-full relative lg:w-2/5 max-h-max p-4">
          <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
            <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
              Téléphone
            </label>
            <input
              type="tel"
              className=" p-2 outline-none"
              placeholder="phone number ...."
              value={tel}
              onChange={(e) => { settel(e.target.value) }}
            />
          </div>
        </div>
        <div className="w-full relative lg:w-2/5 max-h-max p-4">
          <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
            <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
              Email
            </label>
            <input
              type="email"
              className=" p-2 outline-none"
              placeholder="email ...."
              value={email}
              onChange={(e) => { setemail(e.target.value) }}
            />
          </div>
        </div>
        <div className="w-full relative lg:w-2/5 max-h-max p-4">
          <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
            <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
              current password
            </label>
            <input
              type="password"
              className=" p-2 outline-none pr-9"
              placeholder="passowrd ...."
              ref={pas}
              value={passw}
              onChange={(e) => { setPassw(e.target.value) }}
            />
            {open ? (
              <butto
                onClick={(e) => {
                  setOpen(!open);
                  pas.current.type = "password";
                }}
                className="absolute right-5 top-7 mr-1"
              >
                <AiOutlineEye size="25px" />
              </butto>
            ) : (
              <button
                className="absolute right-5 top-7 mr-1"
                onClick={(e) => {
                  setOpen(!open);
                  pas.current.type = "text";
                }}
              >
                <AiOutlineEyeInvisible size="25px" />
              </button>
            )}
          </div>
        </div>
        <div className="w-full relative lg:w-2/5 max-h-max p-4">
          <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
            <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
              Nouveau mot de passe
            </label>
            <input
              type="password"
              className=" p-2 outline-none pr-9"
              placeholder="passowrd ...."
              ref={pas2}
              value={nvPassw}
              onChange={(e) => { setNvPassw(e.target.value) }}
            />
            {open2 ? (
              <butto
                onClick={(e) => {
                  setOpen2(!open2);
                  pas2.current.type = "password";
                }}
                className="absolute right-5 top-7 mr-1"
              >
                <AiOutlineEye size="25px" />
              </butto>
            ) : (
              <button
                className="absolute right-5 top-7 mr-1"
                onClick={(e) => {
                  setOpen2(!open2);
                  pas2.current.type = "text";
                }}
              >
                <AiOutlineEyeInvisible size="25px" />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="flex lg:justify-end w-11/12 m-auto mt-10">
        <button disabled={stop} className="text-md font-semibold text-white p-2 rounded-lg bg-pramery pl-4 pr-4" onClick={() => { Save() }} >
          {" "}
          Modifier
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}
