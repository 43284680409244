import React from 'react'
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai'
import { MdDone } from 'react-icons/md'
import { BsThreeDots } from 'react-icons/bs'
import { BsDownload } from 'react-icons/bs'
import { AiOutlineClose } from 'react-icons/ai'
import userImage from '../assets/Images/user.png'
import {
  fetchDemands,
  NomberDemands,
  paginationDemandsWithoutId,
  changeStatusDemand,
} from '../Firebase/DemandsOperation'
import { getDownloadURL, ref } from 'firebase/storage'
import { storage } from '../Firebase/InitialseApp'

export default function Demands() {
  const [data, setData] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState(1)
  const [nbPage, setNbPage] = React.useState(0)
  const ITEMS_PER_PAGE = 6
  const [lastDoc, setLastDoc] = React.useState(null)
  const [FirstDoc, setFirstDoc] = React.useState(null)
  const [reload, setReload] = React.useState(false)
  const [statusBtnFont] = React.useState({
    Accepté: (
      <p className=" text-green-500 bg-green-300 p-1 rounded-lg">Accepté</p>
    ),
    Reffusé: <p className=" text-red-500 bg-red-300 p-1 rounded-lg">Reffusé</p>,
    attente: (
      <p className=" text-orange-500 bg-orange-300 p-1 rounded-lg">
        En attente
      </p>
    ),
  })

  const taggler = async (event, id, status, user_id, subscriptionPlanId) => {
    if (event.currentTarget.childNodes[1] && status === 'Reffusé') {
      event.currentTarget.childNodes[1].textContent = 'active'
      event.currentTarget.parentElement.style.display = 'none'
      await changeStatusDemand(id, 'Reffusé')
      setReload(!reload)
    } else {
      event.currentTarget.parentElement.style.display = 'none'
      await changeStatusDemand(id, 'Accepté', user_id, subscriptionPlanId)
      setReload(!reload)
    }
  }

  const handleDownload = async (photo) => {
    const link = document.createElement('a')
    link.href = photo
    link.download = 'image.jpg' // Set the desired filename here
    link.target = '_blank'
    link.click()
  }

  function formatDuration(durationInSeconds) {
    const date = new Date(durationInSeconds * 1000) // convert seconds to milliseconds and create a Date object
    const formattedDate = date.toISOString().slice(0, 10) // format the date to a string in "YYYY-MM-DD" format
    return formattedDate
  }

  const paginationNext = async () => {
    const da = await paginationDemandsWithoutId(
      lastDoc.payedAt,
      ITEMS_PER_PAGE,
      'next'
    )
    setData(da)
    setLastDoc(da[da.length - 1])
    setFirstDoc(da[0])
  }

  const paginationPrevious = async () => {
    const da = await paginationDemandsWithoutId(
      FirstDoc.payedAt,
      ITEMS_PER_PAGE,
      'previous'
    )
    setData(da)
    setLastDoc(da[da.length - 1])
    setFirstDoc(da[0])
  }

  const dating = async () => {
    const da = await fetchDemands()
    const n = await NomberDemands()
    setNbPage(n)
    setData(da)
    setFirstDoc(da[0])
    setLastDoc(da[da.length - 1])
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    dating()
  }, [reload])

  return (
    <>
      <div
        className="overflow-auto border-gray-200 w-full rounded-lg mt-10 relative "
        style={{ boxShadow: '0px 0px 25px #d6d2d2', borderRadius: '16px' }}
      >
        <table
          className="table-auto border  w-full text-center pb-10"
          style={{ borderRadius: '16px', minWidth: '1000px' }}
        >
          <thead className="border-b-[1px] border-b-gray-200 text-gray-400  ">
            <tr className="bg-white">
              <th className="pb-5 pt-5 font-medium">ID Paiemnet </th>
              <th className="pb-5 pt-5 font-medium">Utilisateur </th>
              <th className="pb-5 pt-5 font-medium">Date de d'inscription </th>
              <th className="pb-5 pt-5 font-medium">type de plan</th>
              <th className="pb-5 pt-5 font-medium">Adresse email </th>
              <th className="pb-5 pt-5 font-medium">Numémero de telephone </th>
              <th className="pb-5 pt-5 pr-3 font-medium">Status </th>
              <th className="pb-5 pt-5 pr-3 font-medium">Action </th>
            </tr>
          </thead>
          <tbody className=" rounded-lg">
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="relative"
                    style={{
                      background:
                        index % 2 === 0 ? 'rgb(245, 245, 245,0.7)' : 'white',
                    }}
                  >
                    <td className="pt-5 pb-5 text-base">
                      {item.paiement_id ? item.paiement_id.slice(0, 8) : ''}
                    </td>
                    <td className="pt-5 pb-5 text-base flex gap-3 ">
                      <div className=" w-full max-h-max flex justify-center gap-2 items-center ">
                        <img
                          src={item.user.img || userImage}
                          className="w-8 h-8 rounded-lg"
                          alt="img_user"
                        />
                        <p>{item.user.fullname}</p>
                      </div>
                    </td>
                    <td className="pt-5 pb-5 text-base">
                      {formatDuration(item.payedAt.seconds)}
                    </td>
                    <td className="pt-5 pb-5 text-base">
                      {item.NameAbonnement}
                    </td>
                    <td className="pt-5 pb-5 text-sm text-gray-400">
                      {item.user.email}
                    </td>
                    <td className="pt-5 pb-5 text-sm text-gray-400">
                      {item.user.phone}
                    </td>
                    <td className="pt-2 pb-2 text-gray-400">
                      {statusBtnFont[`${item.status}`]}
                    </td>
                    <td className="pt-6 pb-6">
                      <button
                        className=" cursor-pointer"
                        onClick={(event) => {
                          if (event.currentTarget.parentElement.childNodes[1]) {
                            if (
                              event.currentTarget.parentElement.childNodes[1]
                                .style.display === 'none'
                            ) {
                              event.currentTarget.parentElement.childNodes[1].style.display =
                                'flex'
                            } else {
                              event.currentTarget.parentElement.childNodes[1].style.display =
                                'none'
                            }
                          }
                        }}
                      >
                        <BsThreeDots size="20px" />
                      </button>
                      <div
                        className=" w-40 flex-col border border-gray-400  bg-white rounded-lg absolute top-0 right-14 justify-center items-center gap-1 p-1 z-20"
                        style={{ display: 'none' }}
                      >
                        <button
                          className=" flex justify-evenly text-xs w-full hover:bg-gray-200 rounded-xl p-2"
                          onClick={() => {
                            handleDownload(item.receiptUrl)
                          }}
                        >
                          <BsDownload size="20px" />
                          <span>Afficher reçu </span>
                        </button>
                        {item.status === 'attente' ? (
                          <>
                            <button
                              className=" flex text-xs justify-evenly w-full hover:bg-gray-200 rounded-xl p-1"
                              onClick={async (event) => {
                                await taggler(
                                  event,
                                  item.paiement_id,
                                  'Reffusé',
                                  item.userId,
                                  item.subscriptionPlanId
                                )
                              }}
                            >
                              <AiOutlineClose size="20px" />
                              <span>Reffusé</span>
                            </button>
                            <button
                              className=" flex text-xs justify-evenly w-full hover:bg-gray-200 rounded-xl p-1"
                              onClick={async (event) => {
                                await taggler(
                                  event,
                                  item.paiement_id,
                                  'Accepté',
                                  item.userId,
                                  item.subscriptionPlanId
                                )
                              }}
                            >
                              <MdDone size="20px" />
                              <span>Accepté</span>
                            </button>
                          </>
                        ) : null}
                      </div>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
      <div className=" w-full flex lg:justify-end  mt-5">
        <div className="lg:w-1/4 w-1/2 m-auto lg:m-0 flex h-full justify-between p-1 text-sm text-gray-400">
          <div>Nomber de page {Math.ceil(nbPage / ITEMS_PER_PAGE)} </div>
          <div>1-{currentPage} sur 6</div>
          <div className="flex gap-2 ">
            <button
              disabled={currentPage === 1}
              onClick={() => {
                setCurrentPage(currentPage - 1)
                paginationPrevious()
              }}
              className=" relative z-50  w-5 h-5"
            >
              <AiOutlineLeft size="20px" />
            </button>
            <button
              disabled={currentPage > Math.ceil(nbPage / ITEMS_PER_PAGE) - 1}
              onClick={() => {
                setCurrentPage(currentPage + 1)
                paginationNext()
              }}
              className=" relative z-50 w-5 h-5"
            >
              <AiOutlineRight size="20px" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
