import React from "react";
import { MdDone } from "react-icons/md";
import { GrFormClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { FetchAbonnment } from "../Firebase/Typesubscribe";

export default function Abonnement() {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  async function Fetching() {
    const dd = await FetchAbonnment();
    setData([...dd])
  }

  React.useEffect(() => {
    Fetching();
  }, [])
  return (
    <div className="w-full h-full mt-5 p-1 flex flex-col lg:flex-row gap-[3%] justify-center items-center ">
      {/* abonene 1 */}
      {
        data.length > 0 && data.map((plan, index) => {
          return (
            <div
              className="lg:w-[32%] m-auto lg:m-0 max-h-max w-[90%] rounded-lg p-1 pb-3"
              key={index}
              style={{ boxShadow: "0px 0px 10px #d1d1d1" }}
            >
              <div className="flex flex-col w-1/2 m-auto text-pramery mt-10" >
                <p className=" text-3xl font-semibold m-auto">{plan["price"]}</p>
                <p className=" text-xl font-medium m-auto">{plan["name"]}</p>
                <p className=" text-[12px] m-auto mt-4">{"nombre announces"}{" "}{plan["nbAnnounces"]}</p>
              </div>
              <div className="w-full text-gray-300 ">
                {
                  plan.plan.map((e, index) => {
                    return (
                      <div className=" flex justify-between mt-3 w-[96%]  m-auto" key={index}>
                        <div className="w-5 flex justify-center items-center">
                          {
                            e["status"] ?
                              <MdDone size="20px" className=" text-pramery" />
                              : <GrFormClose size="20px" className=" text-pramery" />
                          }
                        </div>
                        <div className="w-[87%]">
                          {e["description"]}
                        </div>
                      </div>
                    );
                  })
                }
              </div>
              <div className=" mt-6 w-full flex items-center justify-center">
                <button
                  onClick={() => {
                    navigate(`${plan["id"]}`);
                  }}
                  className=" p-2 text-white rounded-md bg-pramery "
                >
                  Modification
                </button>
              </div>
            </div>
          );
        })
      }

    </div>
  );
}
