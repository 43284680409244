import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  orderBy,
  limit,
  updateDoc,
  startAfter,
  where,
  endBefore,
} from 'firebase/firestore'

import { db } from './InitialseApp'

export async function getWilays(dateFrom, dateTo) {
  const newDateFrom = new Date(dateFrom)
  const newDateTo = new Date(dateTo)
  const q = query(
    collection(db, 'tripAnnounces'),
    where('created_at', '>=', newDateFrom),
    where('created_at', '<=', newDateTo)
  )
  const data = await getDocs(q)
  let arrayWilays = []

  for (let doc of data.docs) {
    const AnnounceData = doc.data()
    let wilay = AnnounceData.destination || ''
    let found = false
    if (arrayWilays.length === 0) {
      arrayWilays.push(wilay)
    } else {
      for (let wl of arrayWilays) {
        if (wilay.toLowerCase() === wl.toLowerCase()) {
          found = true
          break
        }
      }
      if (!found) {
        arrayWilays.push(wilay)
      }
    }
    wilay = AnnounceData.starting_location || ''
    found = false

    if (arrayWilays.length === 0) {
      arrayWilays.push(wilay)
    } else {
      for (let wl of arrayWilays) {
        if (wilay.toLowerCase() === wl.toLowerCase()) {
          found = true
          break
        }
      }
      if (!found) {
        arrayWilays.push(wilay)
      }
    }
  }
  return arrayWilays.length
}

export async function MostVisiting(dateFrom, dateTo) {
  const newDateFrom = new Date(dateFrom)
  const newDateTo = new Date(dateTo)
  const q = query(
    collection(db, 'tripAnnounces'),
    where('created_at', '>=', newDateFrom),
    where('created_at', '<=', newDateTo)
  )
  const data = await getDocs(q)
  let arrayWilays = []

  for (let doc of data.docs) {
    const AnnounceData = doc.data()
    let wilay = AnnounceData.destination || ''
    let found = false
    let cpt = 0

    if (arrayWilays.length === 0) {
      arrayWilays.push({ wilay: wilay, nb: 1 })
    } else {
      for (let wl of arrayWilays) {
        if (wilay.toLowerCase() === wl.wilay.toLowerCase()) {
          found = true
          break
        }
        cpt++
      }

      if (!found) {
        arrayWilays.push({ wilay: wilay, nb: 1 })
      } else {
        arrayWilays[cpt].nb = arrayWilays[cpt].nb + 1
      }
    }
  }
  let threeElements = []
  arrayWilays.sort((a, b) => b.nb - a.nb)

  if (arrayWilays.length > 3) {
    threeElements = arrayWilays.slice(0, 3)
  } else {
    threeElements = arrayWilays
  }
  return threeElements
}
