import {
    collection,
    getDocs,
    getDoc,
    doc,
    query,
    orderBy,
    limit,
    updateDoc,
    startAfter,
    where,
    endBefore,
    addDoc,
} from "firebase/firestore";

import { db } from "./InitialseApp";


export async function fetchDateOfChart(dateFrom, dateTo) {
    const newDateFrom = new Date(dateFrom);
    const newDateTo = new Date(dateTo);
    const monthlyTotals = [ 0,  0, 0, 0,0,0, 0, 0, 0, 0, 0,0 ]; // Object to store the total price for each month
    const q = query(
        collection(db, "paiements"),
        where('payedAt', '>=', newDateFrom),
        where('payedAt', '<=', newDateTo)
    );
    const data = await getDocs(q);
    const tripAnnounces = [];
    for (const doce of data.docs) {
        const abanmenet = doc(db, "subscriptionPlans", doce.data().subscriptionPlanId);
        const abanmenetData = await getDoc(abanmenet);
        tripAnnounces.push({ ...abanmenetData.data(), ...doce.data() });
        let paymentPrice = parseInt(abanmenetData.data().price);
        const paymentDate = doce.data().payedAt.toDate(); // Assuming payedAt is a Timestamp
        const paymentMonth = paymentDate.getMonth(); // Get the month (0-indexed)      

        // If the month is already in the object, add the payment price to the existing total
        monthlyTotals[paymentMonth] += paymentPrice;
    }

    return monthlyTotals;
}
