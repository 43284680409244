import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./Context";

const PrivateRouters = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation();

  if (
    Object.keys(user).length > 0 &&
    (location.pathname === "/login" || location.pathname === "/foget")
  ) {
    return <Navigate to="/" />;
  }

  if (
    Object.keys(user).length === 0 &&
    location.pathname !== "/login" &&
    location.pathname !== "/forget"
  ) {
    return <Navigate to="/login" />;
  }

  return children;
};
export default PrivateRouters;
