import React from 'react'
import { BsFillAspectRatioFill, BsTrash3 } from 'react-icons/bs'
import {
  AiFillCloseCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from 'react-icons/ai'
import {
  GetSubAdmin,
  remove,
  modificationUser,
  CreateSubAdmin,
} from '../../Firebase/Parametre'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import Edit from '../../assets/Images/edit.png'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAuth } from '../../Hooks/Context'

export default function Moditor({ add, setAdd }) {
  const [open, setOpen] = React.useState(false)
  const [select, setSelect] = React.useState(false)
  const [permission, setPermission] = React.useState([
    { Dashboard: false },
    { Utilisateur: false },
    { Announces: false },
    { Abonnement: false },
    { Application: false },
    { Demands: false },
  ])
  const pas = React.useRef()
  const [data, setData] = React.useState([])
  const [password, setPassword] = React.useState('')
  const [nom, setNom] = React.useState('')
  const [prenom, setPrenom] = React.useState('')
  const [tel, setTel] = React.useState(0)
  const [email, setEmail] = React.useState('')
  const [edit, setEdit] = React.useState(false)
  const [index, setIndex] = React.useState(0)
  const [reload, setReload] = React.useState(false)
  const [userEmail, setUserEmail] = React.useState('')
  const [stop, setStop] = React.useState(false)

  const { token } = useAuth()

  async function Fetching() {
    const dd = await GetSubAdmin()
    setData([...dd])
  }

  const validateEmail = (email) => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    return regex.test(email)
  }

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^(\+213|0)(5|6|7)[0-9]{8}$/
    return regex.test(phoneNumber)
  }

  async function CreateUser(dd) {
    setStop(true)
    const b = await CreateSubAdmin(token, dd)
    setStop(false)
    if (b) {
      toast.success('votre information est enregistré')
    } else {
      toast.error('il y a problem')
    }
    setReload(!reload)
  }

  async function RemoveUser(id) {
    setStop(true)
    const b = await remove(id)
    setStop(false)
    if (b) setReload(!reload)
  }

  async function Update(da) {
    setStop(true)
    const b = await modificationUser(token, userEmail, da)
    setStop(false)
    if (b) {
      toast.success('votre information est enregistré')
    } else {
      toast.error('il y a problem')
    }
    setReload(!reload)
  }

  React.useEffect(() => {
    Fetching()
  }, [reload])

  return (
    <>
      <ToastContainer />
      <div
        className="overflow-auto border-gray-200 w-full rounded-lg mt-10 relative "
        style={{ boxShadow: '0px 0px 25px #d6d2d2', borderRadius: '10px' }}
      >
        <table
          className="table-auto border  w-full text-center pb-10"
          style={{ borderRadius: '10px' }}
        >
          <thead className="border-b-[3px] border-b-gray-200 text-pramery font-semibold ">
            <tr className=" bg-gray-200 ">
              <th className="pb-2 pt-2 font-medium ">ID </th>
              <th className="pb-2 pt-2 font-medium"> Modérateur</th>
              <th className="pb-2 pt-2 font-medium">Email </th>
              <th className="pb-2 pt-2 font-medium">Téléphone</th>
              <th className="pb-2 pt-2 font-medium">Action </th>
            </tr>
          </thead>
          <tbody className=" rounded-lg " style={{ borderRadius: '30px' }}>
            {data.map((e, index) => {
              return (
                <tr
                  className=" border border-gray-300 rounded-lg mt-3 gap-2 "
                  key={index}
                >
                  <td className="pt-3 pb-3 text-base mt-10 ">
                    {e.id_user.slice(0, 8)}
                  </td>
                  <td className="pt-3 pb-3 text-base mt-10">{`${e.nom} ${e.prenom}`}</td>
                  <td className="pt-3 pb-3 text-base mt-10">{e.email}</td>
                  <td className="pt-3 pb-3 text-base mt-10 ">{e.tel}</td>
                  <td className="pt-3 pb-3 text-sm text-gray-400 flex gap-3 justify-center ">
                    <button
                      onClick={() => {
                        setEdit(true)
                        setIndex(index)
                        setUserEmail(data[index].email)
                      }}
                    >
                      <img src={Edit} className=" w-5 h-5" alt="edit" />
                    </button>
                    <button
                      disabled={stop}
                      onClick={() => {
                        RemoveUser(e.id_user)
                      }}
                    >
                      <BsTrash3 size="20px" className=" text-gray-600" />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {edit && (
        <div
          className="w-full h-full fixed top-0 left-0 z-50 flex justify-center items-center"
          style={{ backgroundColor: 'rgba(163, 163, 163,0.5)' }}
        >
          <div
            id="modifie"
            className=" relative max-h-max m-auto lg:w-1/2 flex flex-col p-2 border-white border bg-white rounded-xl lg:ml-[25%]"
            style={{ boxShadow: '0px 0px 10px #d6d2d2' }}
          >
            <button
              className=" absolute right-0"
              onClick={() => {
                setEdit(false)
                // document.body.style.background = "white";
                // document.body.style.pointerEvents = "auto";
              }}
            >
              <AiFillCloseCircle size="30px" />
            </button>
            <div className="w-full h-full flex flex-wrap ">
              <div className="w-full relative lg:w-2/5 max-h-max p-4 mt-3 lg:mt-0">
                <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full ">
                  <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
                    Nom
                  </label>
                  <input
                    type="text"
                    defaultValue={data[index].nom}
                    // value={nom}
                    onChange={(e) => {
                      setNom(e.target.value)
                      // data[index].name = `${
                      //   data[index].name.trim().split(" ")[0]
                      // } ${e.target.value}`;
                      // setData(data);
                    }}
                    className=" p-2 outline-none"
                    placeholder="name ...."
                  />
                </div>
              </div>
              <div className="w-full relative lg:w-2/5 max-h-max p-4">
                <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
                  <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
                    Prénom
                  </label>
                  <input
                    type="text"
                    className=" p-2 outline-none"
                    placeholder="name ...."
                    defaultValue={data[index].prenom}
                    // value={prenom}
                    onChange={(e) => {
                      // data[index].name = `${e.target.value} ${
                      //   data[index].name.trim().split(" ")[1]
                      // }`;
                      // setData(data);
                      setPrenom(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="w-full relative lg:w-2/5 max-h-max p-4">
                <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
                  <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
                    Téléphone
                  </label>
                  <input
                    type="tel"
                    defaultValue={data[index].tel}
                    // value={tel}
                    onChange={(e) => {
                      // data[index].tel = e.target.value;
                      // setData(data);
                      setTel(e.target.value)
                    }}
                    className=" p-2 outline-none"
                    placeholder="phone number ...."
                  />
                </div>
              </div>
              <div className="w-full relative lg:w-2/5 max-h-max p-4">
                <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
                  <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
                    Email
                  </label>
                  <input
                    defaultValue={data[index].email}
                    // value={email}
                    onChange={(e) => {
                      // data[index].email = e.target.value;
                      // setData(data);
                      setEmail(e.target.value)
                    }}
                    type="email"
                    className=" p-2 outline-none"
                    placeholder="email ...."
                  />
                </div>
              </div>

              <div className="w-full relative lg:w-2/5 max-h-max p-4">
                <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
                  <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
                    Permissions
                  </label>
                  <div className="w-full h-12" onClick={() => {}}></div>
                  {select ? (
                    <butto
                      className="absolute right-5 top-7 mr-1"
                      onClick={(e) => {
                        setSelect(!select)
                      }}
                    >
                      <BsChevronDown size="25px" />
                    </butto>
                  ) : (
                    <button
                      className="absolute right-5 top-7 mr-1"
                      onClick={(e) => {
                        setSelect(!select)
                      }}
                    >
                      <BsChevronUp size="25px" />
                    </button>
                  )}
                  {select && (
                    <div className=" w-64 top-16 left-6 max-h-max absolute bg-white p-2 rounded-lg overflow-y-auto h-44 z-50">
                      <div className="p-2 flex gap-3">
                        {' '}
                        <input
                          type="checkbox"
                          defaultChecked={data[index].permission[0].Dashboard}
                          onClick={(e) => {
                            const dd = data[index].permission
                            dd[0].Dashboard = e.target.checked
                            setPermission([...dd])
                          }}
                        />{' '}
                        <p>Dashboard</p>
                      </div>
                      <div className="p-2 flex gap-3">
                        {' '}
                        <input
                          type="checkbox"
                          defaultChecked={data[index].permission[1].Utilisateur}
                          onClick={(e) => {
                            const dd = data[index].permission
                            dd[1].Utilisateur = e.target.checked
                            setPermission([...dd])
                          }}
                        />{' '}
                        <p>Utilisateur</p>
                      </div>
                      <div className="p-2 flex gap-3">
                        {' '}
                        <input
                          type="checkbox"
                          defaultChecked={data[index].permission[2].Announces}
                          onClick={(e) => {
                            const dd = data[index].permission
                            dd[2].Announces = e.target.checked
                            setPermission([...dd])
                          }}
                        />{' '}
                        <p>Announces</p>
                      </div>
                      <div className="p-2 flex gap-3">
                        {' '}
                        <input
                          type="checkbox"
                          defaultChecked={data[index].permission[3].Abonnement}
                          onClick={(e) => {
                            const dd = data[index].permission
                            dd[3].Abonnement = e.target.checked
                            setPermission([...dd])
                          }}
                        />{' '}
                        <p>Abonnement</p>
                      </div>
                      <div className="p-2 flex gap-3">
                        {' '}
                        <input
                          type="checkbox"
                          defaultChecked={data[index].permission[4].Application}
                          onClick={(e) => {
                            const dd = data[index].permission
                            dd[4].Application = e.target.checked
                            setPermission([...dd])
                          }}
                        />{' '}
                        <p>Application</p>
                      </div>
                      <div className="p-2 flex gap-3">
                        {' '}
                        <input
                          type="checkbox"
                          defaultChecked={data[index].permission[5].Demands}
                          onClick={(e) => {
                            const dd = data[index].permission
                            dd[5].Demands = e.target.checked
                            setPermission([...dd])
                          }}
                        />{' '}
                        <p>Demands</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full relative lg:w-2/5 max-h-max p-4">
                <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
                  <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
                    Attribuer mot de passe
                  </label>
                  <input
                    type="password"
                    // defaultValue={data[index].password}
                    onChange={(e) => {
                      // data[index].password = e.target.value;
                      // setData(data);
                      setPassword(e.target.value)
                    }}
                    className=" p-2 outline-none pr-9"
                    placeholder="passowrd ...."
                    value={password}
                    ref={pas}
                  />
                  {open ? (
                    <butto
                      className="absolute right-5 top-7 mr-1"
                      onClick={(e) => {
                        setOpen(!open)
                        pas.current.type = 'password'
                      }}
                    >
                      <AiOutlineEye size="25px" />
                    </butto>
                  ) : (
                    <button
                      className="absolute right-5 top-7 mr-1"
                      onClick={(e) => {
                        setOpen(!open)
                        pas.current.type = 'text'
                      }}
                    >
                      <AiOutlineEyeInvisible size="25px" />
                    </button>
                  )}
                </div>
              </div>
            </div>
            <button
              disabled={stop}
              className="text-md bg-pramery w-1/3 m-auto text-white rounded-lg p-3"
              onClick={async () => {
                if (tel !== 0) data[index].tel = tel
                if (email !== '') data[index].email = email
                if (password !== '') data[index].password = password
                if (nom !== '') data[index].nom = nom
                if (prenom !== '') data[index].prenom = prenom
                setEmail('')
                setTel(0)
                setNom('')
                setPrenom('')
                setPassword('')
                setIndex(0)
                setData([...data])
                document.body.style.background = 'white'
                if (permission.length > 0) {
                  data[index].permission = permission
                }
                if (!validatePhoneNumber(data[index].tel)) {
                  toast.error('vérifie votre information de telephone')
                  return
                }
                if (!validateEmail(data[index].email)) {
                  toast.error('vérifie votre information de email')
                  return
                }
                setPermission([
                  { Dashboard: false },
                  { Utilisateur: false },
                  { Announces: false },
                  { Abonnement: false },
                  { Application: false },
                  { Demands: false },
                ])
                await Update(data[index])
                setEdit(false)
              }}
            >
              Modifie
            </button>
          </div>
        </div>
      )}
      {add && (
        <div
          className="w-full h-full fixed top-0 left-0 z-50 flex justify-center items-center"
          style={{ backgroundColor: 'rgba(163, 163, 163,0.5)' }}
        >
          <div
            id="modifie"
            className=" relative max-h-max m-auto lg:w-3/5 flex flex-col p-2 border-white border bg-white rounded-xl"
            style={{ boxShadow: '0px 0px 10px #a3a3a3' }}
          >
            <button
              className=" absolute right-0"
              onClick={() => {
                setAdd()
                document.body.style.background = 'white'
                // document.body.style.pointerEvents = "auto";
              }}
            >
              <AiFillCloseCircle size="30px" />
            </button>
            <div className="w-full h-full flex flex-wrap ">
              <div className="w-full relative lg:w-2/5 max-h-max p-4 mt-3 lg:mt-0">
                <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full ">
                  <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
                    Nom
                  </label>
                  <input
                    type="text"
                    // defaultValue={data[index].name}
                    // value={nom}
                    onChange={(e) => {
                      setNom(e.target.value)
                      // data[index].name = `${
                      //   data[index].name.trim().split(" ")[0]
                      // } ${e.target.value}`;
                      // setData(data);
                    }}
                    className=" p-2 outline-none"
                    placeholder="name ...."
                  />
                </div>
              </div>
              <div className="w-full relative lg:w-2/5 max-h-max p-4">
                <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
                  <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
                    Prénom
                  </label>
                  <input
                    type="text"
                    className=" p-2 outline-none"
                    placeholder="name ...."
                    // defaultValue={data[index].prenom}
                    // value={prenom}
                    onChange={(e) => {
                      // data[index].name = `${e.target.value} ${
                      //   data[index].name.trim().split(" ")[1]
                      // }`;
                      // setData(data);
                      setPrenom(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="w-full relative lg:w-2/5 max-h-max p-4">
                <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
                  <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
                    Téléphone
                  </label>
                  <input
                    type="tel"
                    // defaultValue={data[index].tel}
                    // value={tel}
                    onChange={(e) => {
                      // data[index].tel = e.target.value;
                      // setData(data);
                      setTel(e.target.value)
                    }}
                    className=" p-2 outline-none"
                    placeholder="phone number ...."
                  />
                </div>
              </div>
              <div className="w-full relative lg:w-2/5 max-h-max p-4">
                <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
                  <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
                    Email
                  </label>
                  <input
                    // defaultValue={data[index].email}
                    // value={email}
                    onChange={(e) => {
                      // data[index].email = e.target.value;
                      // setData(data);
                      setEmail(e.target.value)
                    }}
                    type="email"
                    className=" p-2 outline-none"
                    placeholder="email ...."
                  />
                </div>
              </div>
              <div className="w-full relative lg:w-2/5 max-h-max p-4">
                <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
                  <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
                    Permissions
                  </label>
                  <div className="w-full h-12" onClick={() => {}}></div>
                  {select ? (
                    <butto
                      className="absolute right-5 top-7 mr-1"
                      onClick={(e) => {
                        setSelect(!select)
                      }}
                    >
                      <BsChevronDown size="25px" />
                    </butto>
                  ) : (
                    <button
                      className="absolute right-5 top-7 mr-1"
                      onClick={(e) => {
                        setSelect(!select)
                      }}
                    >
                      <BsChevronUp size="25px" />
                    </button>
                  )}
                  {select && (
                    <div className=" w-64 top-16 left-6 max-h-max absolute bg-white p-2 rounded-lg overflow-y-auto h-44 z-50">
                      <div className="p-2 flex gap-3">
                        {' '}
                        <input
                          type="checkbox"
                          defaultChecked={permission[0].Dashboard}
                          onClick={(e) => {
                            permission[0].Dashboard = e.target.checked
                            setPermission([...permission])
                          }}
                        />{' '}
                        <p>Dashboard</p>
                      </div>
                      <div className="p-2 flex gap-3">
                        {' '}
                        <input
                          type="checkbox"
                          defaultChecked={permission[1].Utilisateur}
                          onClick={(e) => {
                            permission[1].Utilisateur = e.target.checked
                            setPermission([...permission])
                          }}
                        />{' '}
                        <p>Utilisateur</p>
                      </div>
                      <div className="p-2 flex gap-3">
                        {' '}
                        <input
                          type="checkbox"
                          defaultChecked={permission[2].Announces}
                          onClick={(e) => {
                            permission[2].Announces = e.target.checked
                            setPermission([...permission])
                          }}
                        />{' '}
                        <p>Announces</p>
                      </div>
                      <div className="p-2 flex gap-3">
                        {' '}
                        <input
                          type="checkbox"
                          defaultChecked={permission[3].Abonnement}
                          onClick={(e) => {
                            permission[3].Abonnement = e.target.checked
                            setPermission([...permission])
                          }}
                        />{' '}
                        <p>Abonnement</p>
                      </div>
                      <div className="p-2 flex gap-3">
                        {' '}
                        <input
                          type="checkbox"
                          defaultChecked={permission[4].Application}
                          onClick={(e) => {
                            permission[4].Application = e.target.checked
                            setPermission([...permission])
                          }}
                        />{' '}
                        <p>Application</p>
                      </div>
                      <div className="p-2 flex gap-3">
                        {' '}
                        <input
                          type="checkbox"
                          defaultChecked={permission[5].Demands}
                          onClick={(e) => {
                            permission[5].Demands = e.target.checked
                            setPermission([...permission])
                          }}
                        />{' '}
                        <p>Demands</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full relative lg:w-2/5 max-h-max p-4">
                <div className=" border border-gray-400  flex flex-col rounded-lg p-1 w-full">
                  <label className="  absolute top-1 left-8 bg-white text-gray-500 ">
                    Attribuer mot de passe
                  </label>
                  <input
                    type="password"
                    // defaultValue={data[index].password}
                    onChange={(e) => {
                      // data[index].password = e.target.value;
                      // setData(data);
                      setPassword(e.target.value)
                    }}
                    className=" p-2 outline-none pr-9"
                    placeholder="passowrd ...."
                    // value={password}
                    ref={pas}
                  />
                  {open ? (
                    <butto
                      className="absolute right-5 top-7 mr-1"
                      onClick={(e) => {
                        setOpen(!open)
                        pas.current.type = 'password'
                      }}
                    >
                      <AiOutlineEye size="25px" />
                    </butto>
                  ) : (
                    <button
                      className="absolute right-5 top-7 mr-1"
                      onClick={(e) => {
                        setOpen(!open)
                        pas.current.type = 'text'
                      }}
                    >
                      <AiOutlineEyeInvisible size="25px" />
                    </button>
                  )}
                </div>
              </div>
            </div>
            <button
              className="text-md bg-pramery w-1/3 m-auto text-white rounded-lg p-3"
              disabled={stop}
              onClick={async () => {
                if (
                  prenom !== '' &&
                  nom !== '' &&
                  tel !== '' &&
                  email !== '' &&
                  password !== ''
                ) {
                  if (!validateEmail(email)) {
                    toast.error('vérifie votre email')
                    return
                  }
                  if (!validatePhoneNumber(tel)) {
                    toast.error('vérifie votre tel')
                    return
                  }
                  await CreateUser({
                    email: email,
                    password: password,
                    tel: tel,
                    prenom: prenom,
                    nom: nom,
                    permission: permission,
                  })
                  setEmail('')
                  setTel(0)
                  setNom('')
                  setPrenom('')
                  setPassword('')
                  setIndex(0)
                  setPermission([
                    { Dashboard: false },
                    { Utilisateur: false },
                    { Announces: false },
                    { Abonnement: false },
                    { Application: false },
                    { Demands: false },
                  ])
                  setAdd()
                  document.body.style.background = 'white'
                }
              }}
            >
              Ajouter
            </button>
          </div>
        </div>
      )}
    </>
  )
}
