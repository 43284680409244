import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  orderBy,
  limit,
  updateDoc,
  startAfter,
  where,
  endBefore,
} from 'firebase/firestore'

import { db } from './InitialseApp'

export async function fetchAnnounce() {
  const q = query(
    collection(db, 'tripAnnounces'),
    orderBy('start_date', 'desc'),
    limit(6)
  )
  const data = await getDocs(q)
  const tripAnnounces = []
  for (const doce of data.docs) {
    const userRef = doc(db, 'users', doce.data().user_id)
    const user = await getDoc(userRef)
    tripAnnounces.push({
      announce_id: doce.id,
      ...doce.data(),
      user: user.data(),
    })
  }

  return tripAnnounces
}

export async function Nomberannounces() {
  const q = query(
    collection(db, 'tripAnnounces'),
    orderBy('start_date', 'desc')
  )
  const data = await getDocs(q)
  const tripAnnounces = []
  for (const doce of data.docs) {
    const userRef = doc(db, 'users', doce.data().user_id)
    const user = await getDoc(userRef)
    tripAnnounces.push({
      announce_id: doce.id,
      ...doce.data(),
      user: user.data(),
    })
  }

  return tripAnnounces.length
}

export async function fetchAnnounceIdUser(id) {
  const q = query(
    collection(db, 'tripAnnounces'),
    where('user_id', '==', `${id}`),
    orderBy('start_date', 'desc'),
    limit(6)
  )
  const data = await getDocs(q)
  const tripAnnounces = []
  for (const doce of data.docs) {
    const userRef = doc(db, 'users', doce.data().user_id)
    const user = await getDoc(userRef)
    tripAnnounces.push({
      announce_id: doce.id,
      ...doce.data(),
      user: user.data(),
    })
  }

  return tripAnnounces
}

export async function changeStatusAnnounce(id, status) {
  const userRef = doc(db, 'tripAnnounces', id)
  await updateDoc(userRef, {
    status: status,
  })
  return true
}

export async function paginationAnnounces(
  id,
  lastOrFirst,
  ITEMS_PER_PAGE,
  type
) {
  const collectionRef = collection(db, 'tripAnnounces')
  let queryRef

  if (type === 'next') {
    queryRef = query(
      collectionRef,
      where('user_id', '==', id), // No need for template literals around `id`
      orderBy('start_date', 'desc'),
      limit(ITEMS_PER_PAGE),
      startAfter(lastOrFirst)
    )
  } else {
    queryRef = query(
      collectionRef,
      where('user_id', '==', id), // No need for template literals around `id`
      orderBy('start_date', 'desc'),
      limit(ITEMS_PER_PAGE),
      endBefore(lastOrFirst)
    )
  }

  const querySnapshot = await getDocs(queryRef)
  const tripAnnounces = []

  for (const docing of querySnapshot.docs) {
    const userRef = doc(db, 'users', docing.data().user_id)
    const userSnapshot = await getDoc(userRef)
    const user = userSnapshot.data()
    tripAnnounces.push({ announce_id: docing.id, ...docing.data(), user: user })
  }

  return tripAnnounces
}

export async function NomberAnnounceIdUser(id) {
  const q = query(
    collection(db, 'tripAnnounces'),
    where('user_id', '==', `${id}`)
  )
  const data = await getDocs(q)
  const tripAnnounces = []
  for (const doce of data.docs) {
    const userRef = doc(db, 'users', doce.data().user_id)
    const user = await getDoc(userRef)
    tripAnnounces.push({
      announce_id: doce.id,
      ...doce.data(),
      user: user.data(),
    })
  }

  return tripAnnounces.length
}

export async function paginationAnnouncesWithoutId(
  lastOrFirst,
  ITEMS_PER_PAGE,
  type
) {
  const collectionRef = collection(db, 'tripAnnounces')
  let queryRef

  if (type === 'next') {
    queryRef = query(
      collectionRef,
      orderBy('start_date', 'desc'),
      limit(ITEMS_PER_PAGE),
      startAfter(lastOrFirst)
    )
  } else {
    queryRef = query(
      collectionRef,
      orderBy('start_date', 'desc'),
      limit(ITEMS_PER_PAGE),
      endBefore(lastOrFirst)
    )
  }

  const querySnapshot = await getDocs(queryRef)
  const tripAnnounces = []

  for (const docing of querySnapshot.docs) {
    const userRef = doc(db, 'users', docing.data().user_id)
    const userSnapshot = await getDoc(userRef)
    const user = userSnapshot.data()
    tripAnnounces.push({ announce_id: docing.id, ...docing.data(), user: user })
  }

  return tripAnnounces
}

export async function getAnnounce(id) {
  const docRef = doc(db, 'tripAnnounces', id)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    const docUser = doc(db, 'users', docSnap.data().user_id)
    const docUserSnap = await getDoc(docUser)
    const announce = {
      announce_id: docSnap.id,
      ...docSnap.data(),
      user: docUserSnap.data(),
    }
    return announce
  }
  return null
}
