import React from "react";
import { useState, useContext, createContext } from "react";
import { getData_doc } from "../Firebase/Operation";
import { auth } from "../Firebase/InitialseApp";
import logo from "../assets/Images/logo.svg";
import { motion, AnimatePresence } from "framer-motion";
export const AuthContext = createContext(null);

export const ContextProvider = ({ children }) => {
  const [pending, setPending] = React.useState(true);
  const [user, setUser] = useState({});
  const [token, setToken] = useState("")
  const [isMounted, setIsMounted] = useState(false);

  const logoVariants = {
    initial: {
      opacity: 0,
      scale: 0.5,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
        repeat: Infinity,
        repeatDelay: 1,
      },
    },
  };

  const update = (e) => {
    setUser({ ...e });
  };
  async function fetcData(user) {
    const querySnapshot = await getData_doc("users");

    querySnapshot.forEach((doc) => {
      if (doc.id === user.uid && doc.data().role === "admin") {
        setUser(doc.data());
      }
    });
  }

  React.useEffect(() => {
    setIsMounted(true);
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setToken(token)
        fetcData(user);
      } else {
        setUser({});
      }
      setPending(false);
    });
  }, []);

  if (pending) {
    return (
      <AnimatePresence>
        {isMounted && (
          <motion.div
            className="w-24 h-24 m-auto mt-[50%] mg:mt-[30%] lg:mt-[20%] rounded-2xl border border-white"
            style={{ boxShadow: "0px 0px 10px gray" }}
            initial="initial"
            animate="animate"
            variants={logoVariants}
          >
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              width="100"
              height="100"
            >
              <motion.image href={logo} height="100%" width="100%" />
            </motion.svg>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }

  return (
    <AuthContext.Provider value={{ user, update,token }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
