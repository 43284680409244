import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  orderBy,
  limit,
  updateDoc,
  startAfter,
  where,
  endBefore,
} from 'firebase/firestore'

import { db } from './InitialseApp'

// export async function paginationUser(lastOrFirst, ITEMS_PER_PAGE, type) {
//   const collectionRef = collection(db, "users");
//   var queryRef;
//   if (type == "next") {
//     queryRef = query(
//       collectionRef,
//       orderBy("date_inscription", "desc"),
//       limit(ITEMS_PER_PAGE),
//       startAfter(lastOrFirst),
//     );
//   } else {
//     queryRef = query(
//       collectionRef,
//       orderBy("date_inscription", "desc"),
//       limit(ITEMS_PER_PAGE),
//       endBefore(lastOrFirst)
//     );
//   }
//   const data = await getDocs(queryRef);
//   const users = [];
//   for (const doc of data.docs) {
//     if (doc.id !== "test_user") {
//       if (doc.data().role === "client") {
//         users.push({ id_user: doc.id, ...doc.data() });
//       }
//     }
//   }
//   return users;
// }

export async function fetchUsersWithPagination(
  lastOrFirst,
  ITEMS_PER_PAGE,
  type
) {
  const collectionRef = collection(db, 'users')
  let queryRef

  if (type === 'next') {
    queryRef = query(
      collectionRef,
      orderBy('created_at', 'desc'),
      startAfter(lastOrFirst),
      limit(ITEMS_PER_PAGE)
    )
  } else {
    queryRef = query(
      collectionRef,
      orderBy('created_at', 'desc'),
      endBefore(lastOrFirst),
      limit(ITEMS_PER_PAGE)
    )
  }

  const querySnapshot = await getDocs(queryRef)
  const users = []

  querySnapshot.forEach((doc) => {
    if (doc.id !== 'test_user' && doc.data().role === 'client') {
      users.push({ id_user: doc.id, ...doc.data() })
    }
  })

  return users
}

export async function getUserData() {
  const collectionRef = collection(db, 'users')
  const queryRef = query(collectionRef, orderBy('created_at', 'desc'), limit(6))
  const data = await getDocs(queryRef)
  const users = []
  for (const doct of data.docs) {
    if (doct.data().role === 'client') {
      if (doct.data().subscription !== null) {
        const planRef = doc(
          db,
          'subscriptionPlans',
          doct.data().subscription.subscriptionPlanId
        )
        const plan = await getDoc(planRef)
        users.push({
          id_user: doct.id,
          ...doct.data(),
          typePlanAbonnment: plan.data().name,
        })
      } else
        users.push({
          id_user: doct.id,
          ...doct.data(),
          typePlanAbonnment: null,
        })
    }
  }
  return users
}

export async function NumberUsers(dateFrom, dateTo) {
  const newDateFrom = new Date(dateFrom)
  const newDateTo = new Date(dateTo)
  const q = query(
    collection(db, 'users'),
    where('created_at', '>=', newDateFrom),
    where('created_at', '<=', newDateTo)
  )
  const data = await getDocs(q)
  let nb = 0
  data.forEach((doc) => {
    if (doc.data().role === 'client') nb = nb + 1
  })
  return nb
}

export async function changeStatus(id, status) {
  const userRef = doc(db, 'users', id)
  await updateDoc(userRef, {
    status: status,
  })
  return true
}

export async function NumberPage() {
  const collectionRef = collection(db, 'users')
  const queryRef = query(collectionRef, orderBy('created_at', 'desc'))
  const data = await getDocs(queryRef)

  const users = []
  for (const doc of data.docs) {
    if (doc.data().role === 'client') {
      if (doc.id !== 'test_user') {
        users.push({ id_user: doc.id, ...doc.data() })
      }
    }
  }

  return users.length
}
