import React from 'react'
import { BiImageAdd } from 'react-icons/bi'
import { MdAdd } from 'react-icons/md'
import { BsTrashFill } from 'react-icons/bs'
import {
  FetcheImages,
  RemoveImage,
  UploadImages,
} from '../../Firebase/Application'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function Slider() {
  const [imagesOnline, setImagesOnline] = React.useState([])
  const [imagesOffline, setImagesOffline] = React.useState([])
  const [stop, setStop] = React.useState(false)
  const [rmBtn, setRmBtn] = React.useState(false)
  const [reload, setRoald] = React.useState(false)

  async function UploadImage() {
    setStop(true)
    const b = await UploadImages(imagesOffline, imagesOnline)
    if (b) {
      toast.success('Images enregistrer avec success')
    } else toast.error("Erreur lors de l'enregistrement des images")
    setStop(false)
    setRoald(!reload)
  }
  console.log(imagesOffline)
  console.log(imagesOnline)

  async function FetchingImages() {
    const dd = await FetcheImages()
    setImagesOnline([...dd['images']])
  }

  async function hanlderRemove(imgs) {
    const b = await RemoveImage(imgs)
    if (b) toast.success('suppression est success')
    else toast.error('suppression est échoué')
    setRmBtn(false)
  }
  React.useEffect(() => {
    setImagesOffline([])
    FetchingImages()
  }, [reload])

  return (
    <>
      <div
        style={{ boxShadow: '0px 0px 5px rgb(222, 220, 220)' }}
        className="p-2 w-full lg:w-3/5 rounded-lg max-h-max "
      >
        <ToastContainer />
        <div className="w-full h-56 flex items-center justify-center relative">
          <input
            id="file"
            className="hidden"
            type="file"
            multiple
            onChange={(event) => {
              // setFils([...event.target.files]);
              const img = Array.from(event.target.files).map((e) => {
                return {
                  img: e,
                  url: '',
                }
              })
              setImagesOffline([...img, ...imagesOffline])
            }}
          />
          <label htmlFor="file" className="w-32 ">
            <BiImageAdd className="w-full h-full text-gray-300" />
          </label>
        </div>
        <div className=" w-full flex flex-wrap gap-1 justify-evenly mt-5">
          {imagesOnline.length > 0 &&
            imagesOnline.map((url, index) => {
              return (
                <div className=" w-52 max-h-max relative " key={index}>
                  <div className=" w-52 h-32 rounded-md relative">
                    <button
                      onClick={async () => {
                        setRmBtn(true)
                        const filteredArray = imagesOnline.filter(
                          (element, indexs) => indexs !== index
                        )
                        await hanlderRemove([...filteredArray])
                        setImagesOnline([...filteredArray])
                      }}
                      disabled={rmBtn}
                      className=" absolute top-0 left-0 m-2 "
                    >
                      <BsTrashFill size="18px" className=" text-red-500" />
                    </button>
                    <img
                      src={url.img}
                      alt="images"
                      className="w-full h-full rounded-md bg-cover"
                    />
                  </div>
                  <div className=" w-[80%] m-auto rounded-md h-8  mt-4">
                    <input
                      defaultValue={url.url}
                      className=" w-full h-full rounded-md outline-none bg-slate-100"
                    />
                  </div>
                  <button
                    className=" w-6 h-6 rounded-lg bg-pramery text-white ml-20 mt-3"
                    onClick={(e) => {
                      imagesOnline[index].url =
                        e.currentTarget.parentElement.childNodes[1].childNodes[0].value
                    }}
                  >
                    <MdAdd size="25px" className=" text-white" />
                  </button>
                </div>
              )
            })}
          {imagesOffline.length > 0 &&
            imagesOffline.map((url, index) => {
              return (
                <div className=" w-52 max-h-max relative" key={index}>
                  <div className=" w-52 h-32 rounded-md relative">
                    <button
                      onClick={async () => {
                        const filteredArray = imagesOffline.filter(
                          (element, indexs) => indexs !== index
                        )
                        setImagesOffline([...filteredArray])
                      }}
                      className=" absolute top-0 left-0 m-2 "
                    >
                      <BsTrashFill size="18px" className=" text-red-500" />
                    </button>
                    <img
                      src={URL.createObjectURL(url.img)}
                      alt="images"
                      className="w-full h-full rounded-md bg-cover"
                    />
                  </div>
                  <div className=" w-[80%] m-auto rounded-md h-8 mt-4">
                    <input
                      className=" w-full h-full rounded-md outline-none bg-slate-100 "
                      defaultValue={url.url}
                    />
                  </div>
                  <button
                    className=" w-6 h-6 rounded-lg bg-pramery text-white ml-20 mt-3"
                    onClick={(e) => {
                      imagesOnline[index].url =
                        e.currentTarget.parentElement.childNodes[1].childNodes[0].value
                    }}
                  >
                    <MdAdd size="25px" className=" text-white" />
                  </button>
                </div>
              )
            })}
        </div>
      </div>
      <div className=" mt-6 w-full flex items-center justify-end ">
        <button
          className=" p-2 text-white rounded-md bg-pramery "
          disabled={stop}
          onClick={() => {
            UploadImage()
          }}
        >
          Enregistrer
        </button>
      </div>
    </>
  )
}
