import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  orderBy,
  limit,
  updateDoc,
  startAfter,
  where,
  endBefore,
  addDoc,
} from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'

import { db } from './InitialseApp'
import { storage } from './InitialseApp'

// slider :

const uploadImage = async (file) => {
  const storageRef = ref(storage)
  const uniqueFileName = Date.now() + '-' + file.name
  const imageRef = ref(storageRef, uniqueFileName)

  try {
    await uploadBytes(imageRef, file)
    const downloadURL = await getDownloadURL(imageRef)
    return downloadURL
    // Do something with the download URL, such as saving it to a database
  } catch (error) {
    console.error('Error uploading image:', error)
    // Handle the error appropriately
  }
}

export async function UploadImages(imageOffline, imageOnline) {
  let urls = []
  for (const file of imageOffline) {
    await uploadImage(file.img).then((url) => {
      urls.push({ img: url, url: file.url })
    })
  }
  const arr = [...imageOnline, ...urls]
  try {
    await UpdateImage(arr)
    return true
  } catch {
    return false
  }
}

export async function FetcheImages() {
  const docRef = doc(db, 'Application', 'slider')
  const docSnapshot = await getDoc(docRef)

  if (docSnapshot.exists()) {
    return docSnapshot.data()
  } else {
    // Document doesn't exist
    return null
  }
}

export async function UpdateImage(imgs) {
  const userRef = doc(db, 'Application', 'slider')
  await updateDoc(userRef, {
    images: imgs,
  })
  return true
}

export async function RemoveImage(imgs) {
  const userRef = doc(db, 'Application', 'slider')
  console.log(imgs)
  try {
    await updateDoc(userRef, {
      images: imgs,
    })
    return true
  } catch {
    return false
  }
}

// wilays :

export async function updatWils(wl) {
  const userRef = doc(db, 'Application', 'wilays')
  await updateDoc(userRef, {
    wilays: wl,
  })
  return true
}

export async function FetchWilays() {
  const docRef = doc(db, 'Application', 'wilays')
  const docSnapshot = await getDoc(docRef)

  if (docSnapshot.exists()) {
    return docSnapshot.data()
  } else {
    // Document doesn't exist
    return null
  }
}

export async function EnregistreWilays(Wilayas, WilayasNew) {
  let urls = []
  for (const file of WilayasNew) {
    await uploadImage(file.url).then((url) => {
      file.url = url
    })
  }
  Wilayas.forEach((element) => {
    WilayasNew.forEach((e) => {
      if (element.name === e.name) {
        element.url = e.url
      }
    })
  })
  const arr = [...Wilayas]
  try {
    await updatWils(arr)
    return true
  } catch {
    return false
  }
}

// feedback :

export async function FetchFeedback() {
  const collectionRef = collection(db, 'FeedBack')
  const queryRef = query(collectionRef, orderBy('created_at', 'desc'), limit(6))
  const data = await getDocs(queryRef)
  const FeedBack = []

  for (const docing of data.docs) {
    const docRef = doc(db, 'users', docing.data().user_id)
    const docSnapshot = await getDoc(docRef)
    const feedbackData = docing.data()
    const userSnapshot = docSnapshot.data()
    const feedback = {
      ...feedbackData,
      email: userSnapshot.email,
      fullnam: userSnapshot.fullnam,
      img: userSnapshot.img,
      id: docing.id,
    }
    FeedBack.push(feedback)
  }

  return FeedBack
}

export async function NbFeedBack() {
  const collectionRef = collection(db, 'FeedBack')
  const queryRef = query(collectionRef, orderBy('date_creation', 'desc'))
  const data = await getDocs(queryRef)
  return data.docs.length
}

export async function fetchFeedBackWithPagination(
  lastOrFirst,
  ITEMS_PER_PAGE,
  type
) {
  const collectionRef = collection(db, 'users')
  let queryRef

  if (type === 'next') {
    queryRef = query(
      collectionRef,
      orderBy('date_inscription', 'desc'),
      startAfter(lastOrFirst),
      limit(ITEMS_PER_PAGE)
    )
  } else {
    queryRef = query(
      collectionRef,
      orderBy('date_inscription', 'desc'),
      endBefore(lastOrFirst),
      limit(ITEMS_PER_PAGE)
    )
  }

  const data = await getDocs(queryRef)
  const FeedBack = []

  for (const docing of data.docs) {
    const docRef = doc(db, 'users', docing.data().id_user)
    const docSnapshot = await getDoc(docRef)
    const feedbackData = docing.data()
    const userSnapshot = docSnapshot.data()
    const feedback = {
      ...feedbackData,
      email: userSnapshot.email,
      fullnam: userSnapshot.fullnam,
      img: userSnapshot.img,
      id: docing.id,
    }
    FeedBack.push(feedback)
  }

  return FeedBack
}

/// politique :

export async function uploadImageToFirebase(file) {
  try {
    const storageRef = ref(storage)
    const uniqueFileName = Date.now() + '-' + file.name
    const imageRef = ref(storageRef, uniqueFileName)
    await uploadBytes(imageRef, file)
    const downloadURL = await getDownloadURL(imageRef)
    return downloadURL
  } catch (error) {
    console.log(error)
  }
}

export async function EnregestirContent(content, name, titre) {
  const documentData = {
    description: content,
    titre: titre || 'untitre',
    dernier_editeur: name,
    isremove: false,
  }

  // Add the document to the "politique" collection
  const collectionRef = collection(db, 'politique')
  try {
    const docRef = await addDoc(collectionRef, documentData)
    return true
  } catch (error) {
    console.error('Error adding document:', error)
    return false
  }
}

export async function GetPolitique() {
  const q = query(collection(db, 'politique'))
  const data = await getDocs(q)
  const politique = []
  for (const doce of data.docs) {
    if (!doce.data().isremove) politique.push({ id: doce.id, ...doce.data() })
  }
  return politique
}

export async function RemovePolitique(id) {
  const PolitiqueRef = doc(db, 'politique', id)
  await updateDoc(PolitiqueRef, {
    isremove: true,
  })
  return true
}

export async function FetchingPolitiqueById(id) {
  const PolitiqueRef = doc(db, 'politique', id)
  try {
    const politique = await getDoc(PolitiqueRef)
    return { ...politique.data(), id: politique.id }
  } catch {
    return false
  }
}

export async function EnregestirContentById(id, content, name, titre) {
  const PolitiqueRef = doc(db, 'politique', id)
  try {
    await updateDoc(PolitiqueRef, {
      description: content,
      titre: titre || 'untitre',
      dernier_editeur: name,
      isremove: false,
    })
    return true
  } catch {
    return false
  }
}
