import { motion, AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import logo from '../../assets/Images/logo.svg'

const Animcaiton = () => {
  const [isMounted, setIsMounted] = useState(false)

  const logoVariants = {
    initial: {
      opacity: 0,
      scale: 0.5,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        ease: 'easeInOut',
        repeat: Infinity,
        repeatDelay: 1,
      },
    },
  }
  return (
    <AnimatePresence>
      {isMounted && (
        <motion.div
          className="w-24 h-24 m-auto mt-[50%] mg:mt-[30%] lg:mt-[20%] rounded-2xl border border-white"
          style={{ boxShadow: '0px 0px 10px gray' }}
          initial="initial"
          animate="animate"
          variants={logoVariants}
        >
          <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            width="100"
            height="100"
          >
            <motion.image href={logo} height="100%" width="100%" />
          </motion.svg>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Animcaiton
