import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import Forget from "./Pages/Forget";
import Home from "./Pages/Home";
import Dashboard from "./Pages/Dashboard";
import Utilisateur from "./Pages/Utilisateur";
import UtilisateurId from "./Pages/UtilisateurId";
import AnnonceId from "./Pages/AnnonceId";
import Annonce from "./Pages/Annonce";
// import AnnonceIdUs from "./Pages/AnnonceIdUs";
import Notification from "./Pages/Notification";
import Parametre from "./Pages/Parametre";
import { ContextProvider } from "./Hooks/Context";
import PrivateRouters from "./Hooks/PrivateRoute";
import Abonnement from "./Pages/Abonnement";
import ModificationAbonnement from "./Pages/ModificationAbonnement";
import Application from "./Pages/Application";
import Slider from "./components/Application/Slider";
import Wilayas from "./components/Application/Wilayas";
import Politiques from "./components/Application/Politiques";
import AjoutOUmodification from "./components/Application/AjoutOUmodification";
import Feedback from "./components/Application/Feedback";
import PolitiqueById from './components/Application/PolitiqueById'
import Demands from "./Pages/Demands";
function App() {
  return (
    <>
      <ContextProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRouters>
                  {" "}
                  <Home />
                </PrivateRouters>
              }
            >
              <Route index element={<Dashboard />} />
              <Route path="Utilisateur">
                <Route index element={<Utilisateur />} />
                <Route path=":id">
                  <Route index element={<UtilisateurId />} />
                  <Route path=":id" element={<AnnonceId />} />
                </Route>
              </Route>
              <Route path="Anonces">
                <Route index element={<Annonce />} />
                <Route path=":id" element={<AnnonceId />} />
              </Route>

              <Route path="Abonnement">
                <Route index element={<Abonnement />} />
                <Route
                  path=":id"
                  element={<ModificationAbonnement />}
                />
              </Route>
              <Route path="Application" element={<Application />}>
                <Route index element={<Slider />} />
                <Route path="wilayas" element={<Wilayas />} />
                <Route path="Feedback" element={<Feedback />} />
                <Route path="Politiques" >
                  <Route index element={<Politiques />} />
                  <Route path=":id" element={<PolitiqueById />} />
                </Route>
                <Route
                  path="ajoutOUmodification"
                  element={<AjoutOUmodification />}
                />
              </Route>
              <Route path="Notification" element={<Notification />} />
              <Route path="Parametre" element={<Parametre />} />
              <Route path="Demands" element={<Demands />} />

            </Route>
            <Route
              path="/login"
              element={
                <PrivateRouters>
                  <Login />
                </PrivateRouters>
              }
            />
            <Route
              path="/forget"
              element={
                <PrivateRouters>
                  <Forget />
                </PrivateRouters>
              }
            />
          </Routes>
        </Router>
      </ContextProvider>
    </>
  );
}

export default App;
