// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDuX08nZV7p6cIZAGJMc8l_ujU1zBUsArw",
  authDomain: "fi-tri9ek.firebaseapp.com",
  projectId: "fi-tri9ek",
  storageBucket: "fi-tri9ek.appspot.com",
  messagingSenderId: "237849981342",
  appId: "1:237849981342:web:6190bf06af5f9c6fce268f",
  measurementId: "G-6JXW81QL8M",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app)
// const analytics = getAnalytics(app);
