import React from 'react'
import { CiLocationOn } from 'react-icons/ci'
// import Swiper core and required modules
import { Navigation, Pagination, A11y } from 'swiper'
import { AiFillCar } from 'react-icons/ai'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FaVolumeMute } from 'react-icons/fa'
import { MdOutlinePets } from 'react-icons/md'
import userImage from '../assets/Images/user.png'
import { LiaSmokingSolid } from 'react-icons/lia'
import { MdOutlinePhoneInTalk } from 'react-icons/md'
import Animcaiton from '../components/Animation/animation'
// Import Swiper styles

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import '../assets/css/swiper.css'
import { useLocation } from 'react-router-dom'
import { getAnnounce } from '../Firebase/AnnouncesOperation'

export default function AnnonceId() {
  const location = useLocation()
  const [data, setData] = React.useState(null)
  const [isPanding, setIsPanding] = React.useState(true)

  async function fetchData() {
    const dd = await getAnnounce(location.pathname.split('/Anonces/')[1])
    setData({ ...dd })
    setIsPanding(false)
  }
  function formatHour(date) {
    // Get the hour and minute from the date object
    const dating = new Date(date)
    const hour = dating.getHours()
    const minute = dating.getMinutes()

    // Format the hour and minute as a string with leading zeros if needed
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`
    const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`

    // Return the formatted hour and minute as a string
    return `${formattedHour}:${formattedMinute}`
  }

  function getDayFromDate(date) {
    // Get the day of the week as a number (0-6)
    const dating = new Date(date)
    const dayOfWeek = dating.getDay()

    // Create an array of day names to map the number to a day name
    const days = [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
    ]

    // Use the number to index the array and get the day name
    const dayName = days[dayOfWeek]

    return dayName
  }
  function getPreference(data) {
    const pref = []
    if (data.isMusic)
      pref.push(<FaVolumeMute size="20px" className=" text-orange-300" />)
    if (data.isSmoker)
      pref.push(<LiaSmokingSolid size="20px" className=" text-orange-300" />)
    if (data.isPet)
      pref.push(<MdOutlinePets size="20px" className=" text-orange-300" />)
    if (data.isTalker)
      pref.push(
        <MdOutlinePhoneInTalk size="20px" className=" text-orange-300" />
      )
    return pref
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    fetchData()
  }, [])

  if (isPanding) {
    return <Animcaiton />
  }

  return (
    <>
      <div className="flex w-full justify-start pt-5 text-base text-gray-600 gap-2">
        <p className="">
          Utilisateur <span className="ml-2"> &gt;</span>{' '}
        </p>
        <p>
          {data.user_id.slice(0, 8)}
          <span className=" pl-3">
            {data && data.user && data.user.fullname}
          </span>
        </p>
        <p>&gt;</p>
        <p>
          Annonce <span className=" pl-3">{data.announce_id.slice(0, 8)}</span>{' '}
        </p>
      </div>
      <div
        className="mt-10 w-full max-h-max rounded-lg p-3"
        style={{ boxShadow: '0px 0px 4px #dbd9d9' }}
      >
        <div className="w-full flex justify-between items-center">
          <div className=" flex gap-2">
            <img
              src={(data && data.user.avatar) || userImage}
              alt="image_user"
              className=" w-14 h-14 rounded-full bg-cover"
            />
            <p className=" text-lg font-semibold mt-4">
              {data && data.user.fullname}
            </p>
          </div>
          <p className=" text-3xl font-semibold">{data.price}</p>
        </div>

        <div className="flex justify-between w-full max-h-max mt-4 flex-col sm:flex-row">
          <p className=" text-xl font-semibold">
            {formatHour(data.start_date)}
          </p>
          <div className="flex gap-1">
            <AiFillCar size="20px" className=" text-orange-300" />{' '}
            <span className=" text-blue-300">{data.starting_location}</span>
            &#x27A4; <span>{data.destination}</span>
          </div>
        </div>
        <div className="mt-2">
          <p className=" text-xl font-semibold">Jours de voyage</p>
          <div className=" flex gap-2 mt-2">
            {data.trip_type === 'Un seule fois' ? (
              <div
                className="p-1 rounded-lg border border-[#dbd9d9]"
                style={{ boxShadow: '0px 0px 4px #dbd9d9' }}
              >
                {getDayFromDate(data.start_date)}
              </div>
            ) : data.trip_type === 'Hebdomadaire' ? (
              [
                'Dimanche',
                'Lundi',
                'Mardi',
                'Mercredi',
                'Jeudi',
                'Vendredi',
                'Samedi',
              ].map((e) => {
                return (
                  <div
                    key={e}
                    className="p-1 rounded-lg border-[#dbd9d9]"
                    style={{ boxShadow: '0px 0px 4px #dbd9d9' }}
                  >
                    {e}
                  </div>
                )
              })
            ) : (
              <div
                className="p-1 rounded-lg border-[#dbd9d9]"
                style={{ boxShadow: '0px 0px 4px #dbd9d9' }}
              >
                Mentuel
              </div>
            )}
          </div>
        </div>
        <div className="mt-3 flex justify-between p-3">
          <p className=" text-orange-300">
            {data.seat_capacity}{' '}
            <span className=" pl-3 text-red-800">
              il rest{' '}
              {parseInt(data.seat_capacity) - parseInt(data.available_seats)}
            </span>
          </p>
          <div className="flex gap-3">
            {data && data.user.prefrences
              ? getPreference(data.user.prefrences).map((e) => {
                  return <p key={e}>{e}</p>
                })
              : null}
          </div>
        </div>
      </div>
    </>
  )
}
