import React, { useRef } from "react";
import { verifieCode } from "../../Firebase/Operation";

export default function Valide({ rend, code }) {
  const [cpt, setCpt] = React.useState(30);
  const ipt1 = useRef();
  const ipt2 = useRef();
  const ipt3 = useRef();
  const ipt4 = useRef();

  React.useEffect(() => {
    if (cpt === 30) ipt1.current.focus();
    setTimeout(() => {
      if (cpt < 1) {
        setCpt(0);
      } else {
        setCpt(cpt - 1);
      }
    }, 1050);
  }, [cpt]);

  function handler(val, id) {
    const al = [ipt1, ipt2, ipt3, ipt4];
    if (!isNaN(val) && val !== "") {
      if (id !== "3") al[parseInt(id) + 1].current.focus();
    } else {
      if (val === "") {
        if (id !== "0") al[parseInt(id) - 1].current.focus();
      }
    }
  }
  const handleSubmit = async () => {
    verifieCode(
      `${ipt1.current.value}${ipt2.current.value}${ipt3.current.value}${ipt4.current.value}`
    ).then((res) => {
      if (res) {
        ipt1.current.style.border = "1px solid green";
        ipt2.current.style.border = "1px solid green";
        ipt3.current.style.border = "1px solid green";
        ipt4.current.style.border = "1px solid green";
        code(
          `${ipt1.current.value}${ipt2.current.value}${ipt3.current.value}${ipt4.current.value}`
        );
        setTimeout(() => {
          rend(2);
        }, 6000);
      } else {
        ipt1.current.style.border = "1px solid red";
        ipt2.current.style.border = "1px solid red";
        ipt3.current.style.border = "1px solid red";
        ipt4.current.style.border = "1px solid red";
      }
    });
  };
  return (
    <div className="w-3/4 max-w-sm lg:w-1/2 min-h-[50%] m-auto max-h-max flex flex-col items-center gap-8 mt-10">
      <div className="w-full ">
        {" "}
        <p className="text-sm">
          Entrez le code de vérification envoyé à l’adresse email que vous avez
          saisie ima*******3@gmail.com
        </p>
      </div>
      <div className="w-full gap-2 flex flex-col relative ">
        <div className="w-full h-20 flex justify-center items-center gap-4">
          <input
            ref={ipt1}
            type="text"
            className="w-10 h-10 rounded-md border border-gray-400 text-center text-2xl"
            id="0"
            maxLength="1"
            onKeyUp={(e) => {
              handler(e.target.value, "0");
            }}
          />
          <input
            ref={ipt2}
            id="1"
            maxLength="1"
            type="text"
            className="w-10 h-10 rounded-md border border-gray-400 text-center text-2xl"
            onKeyUp={(e) => {
              handler(e.target.value, "1");
            }}
          />
          <input
            ref={ipt3}
            id="2"
            type="text"
            maxLength="1"
            className="w-10 h-10 rounded-md border border-gray-400 text-center text-2xl"
            onKeyUp={(e) => {
              handler(e.target.value, "2");
            }}
          />
          <input
            ref={ipt4}
            id="3"
            type="text"
            maxLength="1"
            className="w-10 h-10 rounded-md border border-gray-400 text-center text-2xl"
            onKeyUp={(e) => {
              handler(e.target.value, "3");
            }}
          />
        </div>
        {cpt > 0 ? (
          <p className="text-sm">
            Vous devriez recevoir le code en{" "}
            <span className="text-[#4493B6]">{cpt} secondes</span>
          </p>
        ) : (
          <p>
            Vous n'avez pas reçu le code ?{" "}
            <span
              className="text-[#4493B6] cursor-pointer"
              onClick={(e) => {
                setCpt(30);
              }}
            >
              Renvoyer le code
            </span>
          </p>
        )}
      </div>

      <button
        onClick={(e) => {
          handleSubmit();
        }}
        className="w-full rounded-md bg-pramery h-14 text-white text-base font-medium"
      >
        Valider le code
      </button>
    </div>
  );
}
