import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { confirmPassword } from "../../Firebase/Operation";

export default function Pass({ code }) {
  const [error2, setError2] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const navigate = useNavigate();

  const pas = React.useRef();
  const pas2 = React.useRef();
  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  function handleSubmit() {
    setError2("");
    if (password1 === password2) {
      confirmPassword(code, password1).then((res) => {
        if (res) {
          navigate("/login");
        } else {
          console.log(res);
        }
      });
    } else {
      // setError2("Les mots de passe ne sont pas identiques");
      setError2("Les mots de passe ne sont pas identiques");
    }
  }
  return (
    <div className="w-3/4 lg:w-1/2 flex flex-col gap-4 m-auto">
      <div className="w-full mt-10">
        {" "}
        <p className="text-sm text-center">Changez votre mot de passe</p>
      </div>
      <div className="w-full gap-2 flex flex-col">
        <p className="text-[#4493B6]"> Nouveau mot de passe</p>
        <div
          className={
            error2 === ""
              ? "w-full h-14 rounded-md border border-gray-300 flex relative"
              : "w-full h-14 rounded-md border border-red-400 flex relative"
          }
        >
          <input
            ref={pas}
            type="password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            className="w-full h-full pl-4 pr-10 rounded-md border"
          />
          {open ? (
            <butto
              className="absolute right-0 top-3 mr-1"
              onClick={(e) => {
                setOpen(!open);
                pas.current.type = "text";
              }}
            >
              <AiOutlineEye size="30px" />
            </butto>
          ) : (
            <button
              className="absolute right-0 top-3 mr-1"
              onClick={(e) => {
                setOpen(!open);
                pas.current.type = "password";
              }}
            >
              <AiOutlineEyeInvisible size="30px" />
            </button>
          )}
        </div>
      </div>
      <div className="w-full gap-2 flex flex-col">
        <p className="text-[#4493B6]"> Réécrire le mot de passe</p>
        <div
          className={
            error2 === ""
              ? "w-full h-14 rounded-md border border-gray-300 flex relative"
              : "w-full h-14 rounded-md border border-red-400 flex relative"
          }
        >
          <input
            ref={pas2}
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            className="w-full h-full pl-4 pr-10 rounded-md border"
          />
          {open1 ? (
            <butto
              className="absolute right-0 top-3 mr-1"
              onClick={(e) => {
                setOpen1(!open1);
                pas2.current.type = "text";
              }}
            >
              <AiOutlineEye size="30px" />
            </butto>
          ) : (
            <button
              className="absolute right-0 top-3 mr-1"
              onClick={(e) => {
                setOpen1(!open1);
                pas2.current.type = "password";
              }}
            >
              <AiOutlineEyeInvisible size="30px" />
            </button>
          )}
        </div>
        <p className="text-red-400 text-sm m-auto">{error2}</p>
      </div>
      <button
        onClick={(e) => {
          handleSubmit();
        }}
        className="w-full rounded-md bg-pramery h-14 text-white text-base font-medium"
      >
        Réinitialiser mot de passe
      </button>
    </div>
  );
}
